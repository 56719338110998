import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { RootStoreProvider } from "./modules/root/store/RootStore";
import { BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(
  <React.StrictMode>
    <RootStoreProvider>
      <Router>
        <App />
      </Router>
    </RootStoreProvider>
  </React.StrictMode>,
);

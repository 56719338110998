import {
  CommonErrors,
  DEFAULT_PERCENTAGE,
  LIST_WITH_SINGLE_ELEMENT,
  NetworkingError,
  SurveySettingsDialogModel,
  SurveySettingsModel,
  createSurveySettingsDialogModel,
  createSurveySettingsModel,
} from "@pulse/shared-components";
import { Instance, cast, flow, getRoot, types } from "mobx-state-tree";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  GenerateGenericSurveyLinkRPC,
  GetCurrentQuestionAndSettingsRPC,
  QuestionType,
  RestartSurveyRPC,
  SaveDraftRPC,
  SwitchLanguageRPC,
} from "@pulse/pulse-rpcs";
import {
  useGenerateGenericSurveyLinkRPCClientImpl,
  useGetCurrentQuestionAndSettingsRPCClientImpl,
  useRestartSurveyRPCClientImpl,
  useSaveDraftRPCClientImpl,
  useSwitchLanguageRPCClientImpl,
} from "../rpcs/RPC";
import { getAPIClient } from "../rpcs/APIClient";
import {
  PrimaryColorTokensModel,
  createPrimaryColorTokensModel,
} from "../models/PrimaryColorTokensModel";
import {
  CurrentScreenDetailsStore,
  createCurrentScreenDetailsStore,
} from "../../surveyRespondent/store/CurrentScreenDetailsStore";
import { createRespondentQuestionDetailsModel } from "../../surveyRespondent/store/RespondentQuestionDetailsModel";
import { RootStore } from "./RootStore";
import { APIClient } from "@surya-digital/tedwig";
import {
  createRTEFileServiceModel,
  RTEFileServiceModel,
} from "../models/RTEFileServiceModel";

export enum SurveyRespondentError {
  InvalidSurveyLinkId = "INVALID_SURVEY_LINK_ID",
  SurveyIsClosed = "SURVEY_IS_CLOSED",
  SurveyAlreadySubmitted = "SURVEY_ALREADY_SUBMITTED",
}

export enum SaveAndGetNextQuestionErrors {
  InvalidQuestionId = "INVALID_QUESTION_ID",
  InvalidAnswerJson = "INVALID_ANSWER_JSON",
}

export enum SaveDraftErrors {
  InvalidQuestionId = "INVALID_QUESTION_ID",
  InvalidAnswerJson = "INVALID_ANSWER_JSON",
}

export enum BacktrackAndGetPreviousQuestionErrors {
  PreviousQuestionNotFound = "PREVIOUS_QUESTION_NOT_FOUND",
}

export enum SwitchSurveyLanguageErrors {
  InvalidLanguageId = "INVALID_LANGUAGE_ID",
  InvalidQuestionId = "INVALID_QUESTION_ID",
}

export enum GenerateGenericSurveyLinkError {
  SurveyNotActive = "SURVEY_NOT_ACTIVE",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
}

export const SurveyStore = types
  .model("SurveyStore", {
    isRPCLoading: types.optional(types.boolean, false),
    isGetCurrentQuestionAndSettingsRPCRPCLoading: types.optional(
      types.boolean,
      false,
    ),
    isFirstQuestion: types.optional(types.boolean, false),
    progressPercentage: types.number,
    isWelcomePageShown: types.optional(types.boolean, false),
    hasRespondentConsented: types.optional(types.boolean, false),
    lightThemeTokens: types.maybeNull(PrimaryColorTokensModel),
    darkThemeTokens: types.maybeNull(PrimaryColorTokensModel),
    startButtonText: types.maybe(types.string),
    genericErrorTitleText: types.maybeNull(types.string),
    continueButtonText: types.maybeNull(types.string),
    nextButtonText: types.maybeNull(types.string),
    submitButtonText: types.maybe(types.string),
    backButtonText: types.maybeNull(types.string),
    restartSurveyTexts: types.maybeNull(SurveySettingsDialogModel),
    saveDraftTexts: types.maybeNull(SurveySettingsDialogModel),
    surveySettings: types.maybeNull(SurveySettingsModel),
    tempSelectedSurveyLanguageCode: types.maybe(types.string),
    rpcError: types.maybeNull(
      types.union(
        types.enumeration("rpcErrors", Object.values(SurveyRespondentError)),
        types.enumeration(
          "saveAndGetNextQuestionErrors",
          Object.values(SaveAndGetNextQuestionErrors),
        ),
        types.enumeration("SaveDraftErrors", Object.values(SaveDraftErrors)),
        types.enumeration(
          "backtrackAndGetPreviousQuestionErrors",
          Object.values(BacktrackAndGetPreviousQuestionErrors),
        ),
        types.enumeration("networkErrors", Object.values(NetworkingError)),
        types.enumeration(
          "switchSurveyLanguageErrors",
          Object.values(SwitchSurveyLanguageErrors),
        ),
        types.enumeration(
          "GenerateGenericSurveyLinkError",
          Object.values(GenerateGenericSurveyLinkError),
        ),
      ),
    ),
    currentScreenDetails: CurrentScreenDetailsStore,
    isAutoProgressEnabedForSingleSelect: types.optional(types.boolean, false),
    surveyIsClosedTitleText: types.maybe(types.string),
    surveyIsClosedDescriptionText: types.maybe(types.string),
    isSaveDraftDialogShown: types.boolean,
    surveyInfoDialogTitle: types.maybe(types.string),
    surveyInfoDialogDescription: types.maybe(types.string),
    surveyInfoDialogPrimaryActionText: types.maybe(types.string),
    isSurveyDataReceived: types.optional(types.boolean, false),
    isNetworkCallMade: types.optional(types.boolean, false),
    rteFileServiceStore: RTEFileServiceModel,
  })
  .views((store) => ({
    get doesStoreContainErrors(): boolean {
      return store.rpcError !== null;
    },
    get isThankyouPageShown(): boolean {
      return (
        store.rpcError !== SurveyRespondentError.SurveyAlreadySubmitted &&
        store.currentScreenDetails.thankYouPageText !== undefined
      );
    },
    get isSurveyAlreadySubmitted(): boolean {
      return store.rpcError === SurveyRespondentError.SurveyAlreadySubmitted;
    },
    get isWelcomePageVisible(): boolean {
      return (
        store.surveySettings?.startButtonText !== undefined &&
        store.currentScreenDetails.respondentQuestionDetails === null
      );
    },
    get isMessageQuestion(): boolean {
      return (
        store.currentScreenDetails.respondentQuestionDetails?.questionType ===
        QuestionType.QuestionType.MESSAGE
      );
    },
    get isSingleLanguageAvailable(): boolean {
      return (
        store.surveySettings?.languageList.length === LIST_WITH_SINGLE_ELEMENT
      );
    },
    get getTempSelectedSurveyLanguageId(): string | undefined {
      return store.surveySettings?.languageList.find(
        (language) =>
          language.languageCode === store.tempSelectedSurveyLanguageCode,
      )?.languageId;
    },
  }))
  .actions((store) => ({
    setHasUserConsentedToTrue: (): void => {
      store.hasRespondentConsented = true;
    },
    setSurveyIsClosedTitleText: (surveyIsClosedTitleText: string): void => {
      store.surveyIsClosedTitleText = surveyIsClosedTitleText;
    },
    setSurveyIsClosedDescriptionText: (
      surveyIsClosedDescriptionText: string,
    ): void => {
      store.surveyIsClosedDescriptionText = surveyIsClosedDescriptionText;
    },
    setIsNetworkCallMade: (isNetworkCallMade: boolean): void => {
      store.isNetworkCallMade = isNetworkCallMade;
    },
    setIsSurveyDataReceived: (isSurveyDataReceived: boolean): void => {
      store.isSurveyDataReceived = isSurveyDataReceived;
    },
    clearRpcErrors: (): void => {
      store.rpcError = null;
    },
    setIsRPCLoading: (isRPCLoading: boolean): void => {
      store.isRPCLoading = isRPCLoading;
    },
    setRpcErrors: (
      rpcError:
        | SurveyRespondentError
        | SaveAndGetNextQuestionErrors
        | BacktrackAndGetPreviousQuestionErrors
        | NetworkingError,
    ): void => {
      store.rpcError = rpcError;
    },
    setProgressPercentage: (progressPercentage: number): void => {
      store.progressPercentage = progressPercentage;
    },
    setIsFirstQuestion: (isFirstQuestion: boolean): void => {
      store.isFirstQuestion = isFirstQuestion;
    },
    setSelectedSurveyLanguageCode: (newSelectedLanguageCode: string): void => {
      if (store.surveySettings) {
        store.surveySettings.selectedLanguageCode = newSelectedLanguageCode;
        store.tempSelectedSurveyLanguageCode = newSelectedLanguageCode;
      }
    },
    setTempSelectedSurveyLanguageCode: (
      newTempSelectedLanguageCode: string,
    ): void => {
      store.tempSelectedSurveyLanguageCode = newTempSelectedLanguageCode;
    },
    setSubmitButtonText: (submitButtonText: string | undefined): void => {
      store.submitButtonText = submitButtonText;
    },
    generateGenericSurveyLink: flow(function* (
      surveyId: string,
      projectId: string,
    ) {
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GenerateGenericSurveyLinkRPC.Request(
          new LeoUUID(surveyId),
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: GenerateGenericSurveyLinkRPC.Response;
          error?: GenerateGenericSurveyLinkRPC.Errors.Errors;
        } =
          yield useGenerateGenericSurveyLinkRPCClientImpl(apiClient).execute(
            request,
          );
        if (response) {
          return response.liveURL;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              store.rpcError = SurveyRespondentError.InvalidSurveyLinkId;
              break;
            case GenerateGenericSurveyLinkError.ProjectAlreadyArchived:
              store.rpcError =
                GenerateGenericSurveyLinkError.ProjectAlreadyArchived;
              break;
            case GenerateGenericSurveyLinkError.SurveyNotActive:
              store.rpcError = GenerateGenericSurveyLinkError.SurveyNotActive;
              break;
            default:
              console.error(`Unhandled error ${error} found`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    getCurrentQuestionAndSettings: flow(function* getCurrentQuestionAndSettings(
      surveyLinkId: string,
      isPreviewURL: boolean,
    ) {
      store.isGetCurrentQuestionAndSettingsRPCRPCLoading = true;
      try {
        const requestSurveyLinkId = new LeoUUID(surveyLinkId);
        const apiClient = getAPIClient(store);
        const request = new GetCurrentQuestionAndSettingsRPC.Request(
          requestSurveyLinkId,
          isPreviewURL,
        );
        const {
          response,
          error,
        }: {
          response?: GetCurrentQuestionAndSettingsRPC.Response;
          error?: GetCurrentQuestionAndSettingsRPC.Errors.Errors;
        } =
          yield useGetCurrentQuestionAndSettingsRPCClientImpl(
            apiClient,
          ).execute(request);
        if (response) {
          store.currentScreenDetails.setCurrentScreenDetails(
            response.currentRespondentScreenDetails,
          );
          store.progressPercentage =
            response.progressPercentage.percentageValue;
          store.surveySettings = createSurveySettingsModel(
            response.respondentSurveySettings.surveySettings,
          );
          store.isWelcomePageShown = response.isWelcomePageShown;
          store.hasRespondentConsented = response.hasRespondentConsented;
          store.lightThemeTokens = cast(
            createPrimaryColorTokensModel(
              response.respondentSurveySettings.lightThemeTokens,
            ),
          );
          store.darkThemeTokens = cast(
            createPrimaryColorTokensModel(
              response.respondentSurveySettings.darkThemeTokens,
            ),
          );
          store.tempSelectedSurveyLanguageCode =
            response.respondentSurveySettings.surveySettings.selectedLanguageCode.languageCode;
          store.restartSurveyTexts = response.respondentSurveySettings
            .restartSurveyDialogProperties
            ? createSurveySettingsDialogModel(
                response.respondentSurveySettings.restartSurveyDialogProperties,
              )
            : null;
          store.saveDraftTexts = response.respondentSurveySettings
            .saveDraftDialogProperties
            ? createSurveySettingsDialogModel(
                response.respondentSurveySettings.saveDraftDialogProperties,
              )
            : null;
          store.surveyInfoDialogTitle =
            response.respondentSurveySettings.surveyInformationDialogProperties?.titleText.text;
          store.surveyInfoDialogDescription =
            response.respondentSurveySettings.surveyInformationDialogProperties?.descriptionText.text;
          store.surveyInfoDialogPrimaryActionText =
            response.respondentSurveySettings.surveyInformationDialogProperties?.primaryActionText.text;
          store.surveySettings = createSurveySettingsModel(
            response.respondentSurveySettings.surveySettings,
          );
          store.submitButtonText = response.submitConfirmationButtonText?.text;
        }

        if (error) {
          switch (error.code) {
            case SurveyRespondentError.InvalidSurveyLinkId:
              store.rpcError = SurveyRespondentError.InvalidSurveyLinkId;
              break;
            case SurveyRespondentError.SurveyIsClosed:
              if (
                error instanceof
                GetCurrentQuestionAndSettingsRPC.Errors.SurveyIsClosed
              ) {
                store.surveyIsClosedTitleText =
                  error.surveyIsClosedTitleText.text;
                store.surveyIsClosedDescriptionText =
                  error.surveyIsClosedDescriptionText.text;
              } else {
                console.error(
                  error,
                  "Error must be instance of SurveyIsClosed.",
                );
              }
              store.rpcError = SurveyRespondentError.SurveyIsClosed;
              break;
            default:
              store.rpcError = null;
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isGetCurrentQuestionAndSettingsRPCRPCLoading = false;
      }
    }),
    restartSurvey: flow(function* restartSurvey(surveyLinkId: string) {
      try {
        store.isRPCLoading = true;
        store.rpcError = null;
        store.currentScreenDetails.validationError = null;
        const requestSurveyLinkId = new LeoUUID(surveyLinkId);
        const apiClient = getAPIClient(store);
        const request = new RestartSurveyRPC.Request(requestSurveyLinkId);
        const {
          response,
          error,
        }: {
          response?: RestartSurveyRPC.Response;
          error?: RestartSurveyRPC.Errors.Errors;
        } = yield useRestartSurveyRPCClientImpl(apiClient).execute(request);

        if (response) {
          if (
            response.questiondetails instanceof
            RestartSurveyRPC.ResponseEnums.Questiondetails.FirstQuestion
          ) {
            store.currentScreenDetails.respondentQuestionDetails =
              createRespondentQuestionDetailsModel(
                response.questiondetails.respondentQuestionDetails,
              );
            store.currentScreenDetails.respondentQuestionDetails.deserializeQuestionJSON();
            store.progressPercentage =
              response.questiondetails.progressPercentage.percentageValue;
            store.isFirstQuestion = true;
          } else if (
            response.questiondetails instanceof
            RestartSurveyRPC.ResponseEnums.Questiondetails.ThankYouPage
          ) {
            store.currentScreenDetails.thankYouPageText =
              response.questiondetails.thankYouPageText.text;
          } else if (
            response.questiondetails instanceof
            RestartSurveyRPC.ResponseEnums.Questiondetails.WelcomePage
          ) {
            store.currentScreenDetails.respondentQuestionDetails = null;
            store.progressPercentage = DEFAULT_PERCENTAGE;
            store.currentScreenDetails.welcomePageText =
              response.questiondetails.welcomePageText.text;
            store.isFirstQuestion = false;
          }
        }
        if (error) {
          switch (error.code) {
            case SurveyRespondentError.InvalidSurveyLinkId:
              store.rpcError = SurveyRespondentError.InvalidSurveyLinkId;
              break;
            case SurveyRespondentError.SurveyAlreadySubmitted:
              if (
                error instanceof RestartSurveyRPC.Errors.SurveyAlreadySubmitted
              ) {
                store.currentScreenDetails.thankYouPageText =
                  error.thankYouPageText.text;
              } else {
                console.error(
                  error,
                  "Error must be instance of SurveyAlreadySubmitted.",
                );
              }
              store.rpcError = SurveyRespondentError.SurveyAlreadySubmitted;
              break;
            case SurveyRespondentError.SurveyIsClosed:
              if (error instanceof RestartSurveyRPC.Errors.SurveyIsClosed) {
                store.surveyIsClosedTitleText =
                  error.surveyIsClosedTitleText.text;
                store.surveyIsClosedDescriptionText =
                  error.surveyIsClosedDescriptionText.text;
              } else {
                console.error(
                  error,
                  "Error must be instance of SurveyIsClosed.",
                );
              }
              store.rpcError = SurveyRespondentError.SurveyIsClosed;
              break;
            default:
              store.rpcError = null;
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
      store.isRPCLoading = false;
    }),
    resetSaveDraftDialog: (): void => {
      store.isSaveDraftDialogShown = false;
    },
    saveDraft: flow(function* saveDraft(surveyLinkId: string) {
      store.isSurveyDataReceived = false;
      store.isNetworkCallMade = true;
      store.rpcError = null;
      store.currentScreenDetails.validationError = null;
      const answerJSON =
        store.currentScreenDetails.respondentQuestionDetails
          ?.serializedAnswerJSON;
      if (answerJSON) {
        try {
          const request = new SaveDraftRPC.Request(
            new LeoUUID(surveyLinkId),
            new LeoUUID(
              store.currentScreenDetails.respondentQuestionDetails?.questionId,
            ),
            answerJSON,
          );
          const apiClient = getAPIClient(store);
          const {
            response,
            error,
          }: {
            response?: SaveDraftRPC.Response;
            error?: SaveDraftRPC.Errors.Errors;
          } = yield useSaveDraftRPCClientImpl(apiClient).execute(request);
          if (response) {
            store.isSaveDraftDialogShown = true;
          } else if (error) {
            switch (error.code) {
              case SurveyRespondentError.InvalidSurveyLinkId:
                store.rpcError = SurveyRespondentError.InvalidSurveyLinkId;
                break;
              case SurveyRespondentError.SurveyAlreadySubmitted:
                if (
                  error instanceof SaveDraftRPC.Errors.SurveyAlreadySubmitted
                ) {
                  store.currentScreenDetails.thankYouPageText =
                    error.thankYouPageText.text;
                } else {
                  console.error(
                    error,
                    "Error must be instance of SurveyAlreadySubmitted.",
                  );
                }
                store.rpcError = SurveyRespondentError.SurveyAlreadySubmitted;
                break;
              case SurveyRespondentError.SurveyIsClosed:
                if (error instanceof SaveDraftRPC.Errors.SurveyIsClosed) {
                  store.surveyIsClosedTitleText =
                    error.surveyIsClosedTitleText.text;
                  store.surveyIsClosedDescriptionText =
                    error.surveyIsClosedDescriptionText.text;
                } else {
                  console.error(
                    error,
                    "Error must be instance of SurveyIsClosed.",
                  );
                }
                store.rpcError = SurveyRespondentError.SurveyIsClosed;
                break;
              case SaveDraftErrors.InvalidAnswerJson:
                store.rpcError = SaveDraftErrors.InvalidAnswerJson;
                break;
              case SaveDraftErrors.InvalidQuestionId:
                store.rpcError = SaveDraftErrors.InvalidQuestionId;
                break;
              default:
                store.rpcError = NetworkingError.InternalError;
                break;
            }
          }
        } catch (e) {
          if (e instanceof Error) {
            const rootStore = getRoot<typeof RootStore>(store);
            rootStore.networkingStore.errorStore.setLeoError(e);
          } else {
            console.error(`Unhandled error occured: ${e}`);
          }
        } finally {
          store.isRPCLoading = false;
          store.isSurveyDataReceived = true;
          store.isNetworkCallMade = false;
        }
      }
    }),
    switchSurveyLanguage: flow(function* switchSurveyLanguage(
      newLanguageId: string,
      surveyLinkId: string,
    ) {
      try {
        store.isRPCLoading = true;
        store.rpcError = null;
        const request = new SwitchLanguageRPC.Request(
          new LeoUUID(newLanguageId),
          new LeoUUID(surveyLinkId),
        );
        const apiClient = getAPIClient(store);
        const {
          response,
          error,
        }: {
          response?: SwitchLanguageRPC.Response;
          error?: SwitchLanguageRPC.Errors.Errors;
        } = yield useSwitchLanguageRPCClientImpl(apiClient).execute(request);

        if (response) {
          store.currentScreenDetails.setCurrentScreenDetails(
            response.currentRespondentScreenDetails,
          );
          store.lightThemeTokens = cast(
            createPrimaryColorTokensModel(
              response.respondentSurveySettings.lightThemeTokens,
            ),
          );
          store.darkThemeTokens = cast(
            createPrimaryColorTokensModel(
              response.respondentSurveySettings.darkThemeTokens,
            ),
          );
          store.tempSelectedSurveyLanguageCode =
            response.respondentSurveySettings.surveySettings.selectedLanguageCode.languageCode;
          store.restartSurveyTexts = response.respondentSurveySettings
            .restartSurveyDialogProperties
            ? createSurveySettingsDialogModel(
                response.respondentSurveySettings.restartSurveyDialogProperties,
              )
            : null;
          store.saveDraftTexts = response.respondentSurveySettings
            .saveDraftDialogProperties
            ? createSurveySettingsDialogModel(
                response.respondentSurveySettings.saveDraftDialogProperties,
              )
            : null;
          store.surveyInfoDialogTitle =
            response.respondentSurveySettings.surveyInformationDialogProperties?.titleText.text;
          store.surveyInfoDialogDescription =
            response.respondentSurveySettings.surveyInformationDialogProperties?.descriptionText.text;
          store.surveyInfoDialogPrimaryActionText =
            response.respondentSurveySettings.surveyInformationDialogProperties?.primaryActionText.text;
          store.surveySettings = createSurveySettingsModel(
            response.respondentSurveySettings.surveySettings,
          );
          store.submitButtonText = response.submitConfirmationButtonText?.text;
        } else if (error) {
          switch (error.code) {
            case SurveyRespondentError.InvalidSurveyLinkId:
              store.rpcError = SurveyRespondentError.InvalidSurveyLinkId;
              break;
            case SurveyRespondentError.SurveyIsClosed:
              if (error instanceof SwitchLanguageRPC.Errors.SurveyIsClosed) {
                store.surveyIsClosedTitleText =
                  error.surveyIsClosedTitleText.text;
                store.surveyIsClosedDescriptionText =
                  error.surveyIsClosedDescriptionText.text;
              } else {
                console.error(
                  error,
                  "Error must be instance of SurveyIsClosed.",
                );
              }
              store.rpcError = SurveyRespondentError.SurveyIsClosed;
              break;
            case SwitchSurveyLanguageErrors.InvalidQuestionId:
              store.rpcError = SwitchSurveyLanguageErrors.InvalidQuestionId;
              break;
            case SwitchSurveyLanguageErrors.InvalidLanguageId:
              store.rpcError = SwitchSurveyLanguageErrors.InvalidLanguageId;
              break;
            default:
              store.rpcError = NetworkingError.InternalError;
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.tempSelectedSurveyLanguageCode =
          store.surveySettings?.selectedLanguageCode;
        store.isRPCLoading = false;
      }
    }),
  }));

export const createSurveyStore = (): Instance<typeof SurveyStore> => {
  return SurveyStore.create({
    progressPercentage: DEFAULT_PERCENTAGE,
    currentScreenDetails: createCurrentScreenDetailsStore(),
    isSaveDraftDialogShown: false,
    rteFileServiceStore: createRTEFileServiceModel(),
  });
};

import {
  ChildQuestionElementV1Enums,
  GroupAnswerElementV1,
  GroupAnswerElementV1Enums,
  GroupAnswerV1,
  GroupAnswerV1DTO,
  GroupWithChildQuestionsV1,
  GroupWithChildQuestionsV1DTO,
  QuestionType,
  RespondentQuestionDetails,
} from "@pulse/pulse-rpcs";
import {
  EMPTY_JSON,
  OpenEndedQuestionV1Model,
  createOpenEndedQuestionV1Model,
  MultiChoiceQuestionV1Model,
  SingleSelectQuestionV1Model,
  createMultiChoiceQuestionV1Model,
  createQuestionOptionsModel,
  createSingleSelectQuestionV1Model,
  GridQuestionV1Model,
  createGridQuestionV1Model,
  RankingQuestionV1Model,
  createRankingQuestionV1Model,
  createRankingQuestionOptionsModel,
  GroupQuestionV1Model,
  createGroupQuestionV1Model,
  ValidationErrors,
} from "@pulse/shared-components";
import { Instance, getParentOfType, types } from "mobx-state-tree";
import { CurrentScreenDetailsStore } from "./CurrentScreenDetailsStore";
import { validateGridAnswer } from "../../../utils/validateGridAnswer";

export const RespondentQuestionDetailsModel = types
  .model("RespondentQuestionDetailsModel", {
    questionDetailsJSON: types.string,
    jsonVersion: types.integer,
    questionType: types.enumeration(Object.values(QuestionType.QuestionType)),
    questionId: types.string,
    questionCode: types.string,
    questionText: types.maybe(types.string),
    questionDescription: types.maybe(types.string),
    isMandatory: types.boolean,
    singleSelectQuestionV1Model: types.maybeNull(SingleSelectQuestionV1Model),
    openEndedQuestionV1Model: types.maybeNull(OpenEndedQuestionV1Model),
    multiChoiceQuestionV1Model: types.maybeNull(MultiChoiceQuestionV1Model),
    gridQuestionV1Model: types.maybeNull(GridQuestionV1Model),
    rankingQuestionV1Model: types.maybeNull(RankingQuestionV1Model),
    groupQuestionV1Model: types.array(GroupQuestionV1Model),
  })
  .views((store) => ({
    get groupQuestionV1ModelLastIndex(): number {
      return store.groupQuestionV1Model.length - 1;
    },
    get serializedAnswerJSON(): string | undefined {
      switch (store.questionType) {
        case QuestionType.QuestionType.SINGLE_CHOICE: {
          return store.singleSelectQuestionV1Model?.serializedAnswerJSON;
        }

        case QuestionType.QuestionType.OPEN_ENDED: {
          return store.openEndedQuestionV1Model?.serializedAnswerJSON;
        }
        case QuestionType.QuestionType.MULTIPLE_CHOICE: {
          return store.multiChoiceQuestionV1Model?.serializedAnswerJSON;
        }
        case QuestionType.QuestionType.RANKING: {
          return store.rankingQuestionV1Model?.serializedAnswerJSON;
        }
        case QuestionType.QuestionType.GRID: {
          return store.gridQuestionV1Model?.serializedAnswerJSON;
        }
        case QuestionType.QuestionType.GROUP: {
          const answers: GroupAnswerElementV1[] = [];
          store.groupQuestionV1Model.forEach((question) => {
            const serializedAnswerJSON = question.getSerializedAnswerJSON();
            if (serializedAnswerJSON !== undefined) {
              answers.push(serializedAnswerJSON);
            }
          });
          return JSON.stringify(new GroupAnswerV1(answers).toDTO());
        }
        case QuestionType.QuestionType.MESSAGE:
        default: {
          return EMPTY_JSON;
        }
      }
    },
  }))
  .actions((store) => ({
    validateAnswer: (answerJSON: string | undefined): void => {
      const currentScreenDetailsStore = getParentOfType(
        store,
        CurrentScreenDetailsStore,
      );
      if (
        !store.isMandatory &&
        store.questionType !== QuestionType.QuestionType.GRID &&
        store.questionType !== QuestionType.QuestionType.RANKING &&
        store.questionType !== QuestionType.QuestionType.GROUP
      ) {
        return;
      }
      if (
        (answerJSON === EMPTY_JSON || answerJSON === undefined) &&
        store.questionType !== QuestionType.QuestionType.MESSAGE
      ) {
        currentScreenDetailsStore.validationError =
          ValidationErrors.MandatoryQuestionNotAnswered;
        return;
      }

      switch (store.questionType) {
        case QuestionType.QuestionType.OPEN_ENDED: {
          if (store.openEndedQuestionV1Model !== null) {
            currentScreenDetailsStore.validationError =
              store.openEndedQuestionV1Model.validateUserResponse;
          } else {
            console.error("openEndedQuestionV1Model cannot be null.");
          }
          break;
        }
        case QuestionType.QuestionType.MULTIPLE_CHOICE: {
          if (store.multiChoiceQuestionV1Model !== null) {
            currentScreenDetailsStore.validationError =
              store.multiChoiceQuestionV1Model.validateUserResponse;
          } else {
            console.error("multiChoiceQuestionV1Model cannot be null.");
          }
          break;
        }
        case QuestionType.QuestionType.RANKING: {
          if (store.rankingQuestionV1Model !== null) {
            currentScreenDetailsStore.validationError =
              store.rankingQuestionV1Model.validateUserResponse(
                store.isMandatory,
              );
          } else {
            console.error("rankingQuestionV1Model cannot be null.");
          }
          break;
        }
        case QuestionType.QuestionType.GROUP: {
          if (store.groupQuestionV1Model !== null) {
            if (answerJSON !== undefined) {
              const groupAnswer = <GroupAnswerV1>JSON.parse(answerJSON);
              if (store.isMandatory) {
                const groupQuestionsSizeWithoutMessage =
                  store.groupQuestionV1Model.filter(
                    (question) =>
                      question.questionType !==
                      QuestionType.QuestionType.MESSAGE,
                  ).length;
                if (
                  groupAnswer.answer.length !== groupQuestionsSizeWithoutMessage
                ) {
                  currentScreenDetailsStore.validationError =
                    ValidationErrors.MandatoryQuestionNotAnswered;
                  return;
                }
                store.groupQuestionV1Model.every((question) => {
                  switch (question.questionType) {
                    case QuestionType.QuestionType.MULTIPLE_CHOICE: {
                      if (question.multiChoiceQuestionV1Model !== null) {
                        currentScreenDetailsStore.validationError =
                          question.multiChoiceQuestionV1Model.validateUserResponse;
                      } else {
                        console.error(
                          "multiChoiceQuestionV1Model cannot be null.",
                        );
                      }
                      break;
                    }
                    case QuestionType.QuestionType.RANKING: {
                      if (question.rankingQuestionV1Model !== null) {
                        currentScreenDetailsStore.validationError =
                          question.rankingQuestionV1Model.validateUserResponse(
                            store.isMandatory,
                          );
                      } else {
                        console.error("rankingQuestionV1Model cannot be null.");
                      }
                      break;
                    }
                    case QuestionType.QuestionType.SINGLE_CHOICE: {
                      if (question.singleSelectQuestionV1Model !== null) {
                        currentScreenDetailsStore.validationError =
                          question.singleSelectQuestionV1Model.validateUserResponse;
                      } else {
                        console.error(
                          "singleSelectQuestionV1Model cannot be null.",
                        );
                      }
                      break;
                    }
                    case QuestionType.QuestionType.OPEN_ENDED: {
                      if (question.openEndedQuestionV1Model) {
                        currentScreenDetailsStore.validationError =
                          question.openEndedQuestionV1Model.validateUserResponse;
                      } else {
                        console.error(
                          "openEndedQuestionV1Model cannot be null.",
                        );
                      }
                      break;
                    }
                    case QuestionType.QuestionType.GRID: {
                      if (question.gridQuestionV1Model !== null) {
                        currentScreenDetailsStore.validationError =
                          validateGridAnswer(
                            store.isMandatory,
                            question.gridQuestionV1Model,
                          );
                      } else {
                        console.error("gridQuestionV1Model cannot be null.");
                      }
                      break;
                    }
                    case QuestionType.QuestionType.MESSAGE: {
                      break;
                    }
                  }
                  // This check is returned here because "every" loop requires a predicate to terminate it's loop. The loop only ends when validation error is found.
                  return currentScreenDetailsStore.validationError === null;
                });
              } else {
                store.groupQuestionV1Model.every((question) => {
                  switch (question.questionType) {
                    case QuestionType.QuestionType.MULTIPLE_CHOICE: {
                      if (question.multiChoiceQuestionV1Model !== null) {
                        if (question.multiChoiceQuestionV1Model.isMandatory) {
                          currentScreenDetailsStore.validationError =
                            question.multiChoiceQuestionV1Model.validateUserResponse;
                        }
                      } else {
                        console.error(
                          "multiChoiceQuestionV1Model cannot be null.",
                        );
                      }
                      break;
                    }
                    case QuestionType.QuestionType.RANKING: {
                      if (question.rankingQuestionV1Model !== null) {
                        currentScreenDetailsStore.validationError =
                          question.rankingQuestionV1Model.validateUserResponse(
                            question.rankingQuestionV1Model.isMandatory,
                          );
                      } else {
                        console.error("rankingQuestionV1Model cannot be null.");
                      }
                      break;
                    }
                    case QuestionType.QuestionType.SINGLE_CHOICE: {
                      if (question.singleSelectQuestionV1Model !== null) {
                        if (question.singleSelectQuestionV1Model.isMandatory) {
                          currentScreenDetailsStore.validationError =
                            question.singleSelectQuestionV1Model.validateUserResponse;
                        }
                      } else {
                        console.error(
                          "singleSelectQuestionV1Model cannot be null.",
                        );
                      }
                      break;
                    }
                    case QuestionType.QuestionType.OPEN_ENDED: {
                      if (question.openEndedQuestionV1Model !== null) {
                        if (question.openEndedQuestionV1Model.isMandatory) {
                          currentScreenDetailsStore.validationError =
                            question.openEndedQuestionV1Model.validateUserResponse;
                        }
                      } else {
                        console.error(
                          "openEndedQuestionV1Model cannot be null.",
                        );
                      }
                      break;
                    }
                    case QuestionType.QuestionType.GRID: {
                      if (question.gridQuestionV1Model !== null) {
                        currentScreenDetailsStore.validationError =
                          validateGridAnswer(
                            question.gridQuestionV1Model.isMandatory,
                            question.gridQuestionV1Model,
                          );
                      } else {
                        console.error("gridQuestionV1Model cannot be null.");
                      }
                      break;
                    }
                    case QuestionType.QuestionType.MESSAGE: {
                      break;
                    }
                  }
                  // This check is returned here because "every" loop requires a predicate to terminate it's loop. The loop only ends when validation error is found.
                  return currentScreenDetailsStore.validationError === null;
                });
              }
            }
          } else {
            console.error("groupQuestionV1Model cannot be null.");
          }
          break;
        }
        case QuestionType.QuestionType.GRID: {
          if (store.gridQuestionV1Model !== null) {
            currentScreenDetailsStore.validationError = validateGridAnswer(
              store.isMandatory,
              store.gridQuestionV1Model,
            );
          } else {
            console.error("gridQuestionV1Model cannot be null.");
          }
          break;
        }
        default: {
          return;
        }
      }
    },
    deserializeQuestionJSON: (): void => {
      switch (store.questionType) {
        case QuestionType.QuestionType.SINGLE_CHOICE: {
          store.singleSelectQuestionV1Model?.deserializeQuestionJSON(
            store.questionDetailsJSON,
          );
          break;
        }
        case QuestionType.QuestionType.OPEN_ENDED: {
          store.openEndedQuestionV1Model?.deserializeQuestionJSON(
            store.questionDetailsJSON,
          );
          break;
        }
        case QuestionType.QuestionType.MULTIPLE_CHOICE: {
          store.multiChoiceQuestionV1Model?.deserializeQuestionJSON(
            store.questionDetailsJSON,
          );
          break;
        }
        case QuestionType.QuestionType.GRID: {
          store.gridQuestionV1Model?.deserializeQuestionJSON(
            store.questionDetailsJSON,
          );
          break;
        }
        case QuestionType.QuestionType.RANKING: {
          store.rankingQuestionV1Model?.deserializeQuestionJSON(
            store.questionDetailsJSON,
          );
          break;
        }
        case QuestionType.QuestionType.GROUP: {
          const groupQuestionV1DTO = <GroupWithChildQuestionsV1DTO>(
            JSON.parse(store.questionDetailsJSON)
          );
          const groupQuestionV1 =
            GroupWithChildQuestionsV1.fromDTO(groupQuestionV1DTO);
          groupQuestionV1.childQuestions.map((question) => {
            if (
              question.childQuestion instanceof
              ChildQuestionElementV1Enums.ChildQuestion.SingleChoice
            ) {
              store.groupQuestionV1Model?.push(
                createGroupQuestionV1Model(
                  QuestionType.QuestionType.SINGLE_CHOICE,
                  question.question?.text,
                  question.questionDescription?.text,
                  question.childQuestionCode.code,
                  question.isMandatory,
                ),
              );
              store.groupQuestionV1Model[
                store.groupQuestionV1ModelLastIndex
              ].deserializeQuestionJSON(
                JSON.stringify(
                  question.childQuestion.singleChoiceQuestionAttributes.toDTO(),
                ),
              );
            } else if (
              question.childQuestion instanceof
              ChildQuestionElementV1Enums.ChildQuestion.Grid
            ) {
              store.groupQuestionV1Model?.push(
                createGroupQuestionV1Model(
                  QuestionType.QuestionType.GRID,
                  question.question?.text,
                  question.questionDescription?.text,
                  question.childQuestionCode.code,
                  question.isMandatory,
                ),
              );
              store.groupQuestionV1Model[
                store.groupQuestionV1ModelLastIndex
              ].deserializeQuestionJSON(
                JSON.stringify(
                  question.childQuestion.gridQuestionAttributes.toDTO(),
                ),
              );
            } else if (
              question.childQuestion instanceof
              ChildQuestionElementV1Enums.ChildQuestion.Message
            ) {
              store.groupQuestionV1Model?.push(
                createGroupQuestionV1Model(
                  QuestionType.QuestionType.MESSAGE,
                  question.question?.text,
                  question.questionDescription?.text,
                  question.childQuestionCode.code,
                  question.isMandatory,
                ),
              );
              store.groupQuestionV1Model[
                store.groupQuestionV1ModelLastIndex
              ].deserializeQuestionJSON(
                JSON.stringify(question.childQuestion.toDTO()),
              );
            } else if (
              question.childQuestion instanceof
              ChildQuestionElementV1Enums.ChildQuestion.MultiChoice
            ) {
              store.groupQuestionV1Model?.push(
                createGroupQuestionV1Model(
                  QuestionType.QuestionType.MULTIPLE_CHOICE,
                  question.question?.text,
                  question.questionDescription?.text,
                  question.childQuestionCode.code,
                  question.isMandatory,
                ),
              );
              store.groupQuestionV1Model[
                store.groupQuestionV1ModelLastIndex
              ].deserializeQuestionJSON(
                JSON.stringify(
                  question.childQuestion.multiChoiceQuestionAttributes.toDTO(),
                ),
              );
            } else if (
              question.childQuestion instanceof
              ChildQuestionElementV1Enums.ChildQuestion.OpenEnded
            ) {
              store.groupQuestionV1Model.push(
                createGroupQuestionV1Model(
                  QuestionType.QuestionType.OPEN_ENDED,
                  question.question?.text,
                  question.questionDescription?.text,
                  question.childQuestionCode.code,
                  question.isMandatory,
                ),
              );
              store.groupQuestionV1Model[
                store.groupQuestionV1ModelLastIndex
              ].deserializeQuestionJSON(
                JSON.stringify(
                  question.childQuestion.openEndedQuestionAttributes.toDTO(),
                ),
              );
            } else if (
              question.childQuestion instanceof
              ChildQuestionElementV1Enums.ChildQuestion.Ranking
            ) {
              store.groupQuestionV1Model?.push(
                createGroupQuestionV1Model(
                  QuestionType.QuestionType.RANKING,
                  question.question?.text,
                  question.questionDescription?.text,
                  question.childQuestionCode.code,
                  question.isMandatory,
                ),
              );
              store.groupQuestionV1Model[
                store.groupQuestionV1ModelLastIndex
              ].deserializeQuestionJSON(
                JSON.stringify(
                  question.childQuestion.rankingQuestionAttributes.toDTO(),
                ),
              );
            }
          });
          break;
        }
        case QuestionType.QuestionType.MESSAGE:
        default: {
          break;
        }
      }
    },
    deserializeAnswerJSON: (): void => {
      const answerJSON: string | null = getParentOfType(
        store,
        CurrentScreenDetailsStore,
      ).answerJson;
      if (answerJSON !== null && answerJSON !== EMPTY_JSON) {
        switch (store.questionType) {
          case QuestionType.QuestionType.SINGLE_CHOICE: {
            store.singleSelectQuestionV1Model?.deserializeAnswerJSON(
              answerJSON,
            );
            break;
          }
          case QuestionType.QuestionType.OPEN_ENDED: {
            store.openEndedQuestionV1Model?.deserializeAnswerJSON(answerJSON);
            break;
          }
          case QuestionType.QuestionType.MULTIPLE_CHOICE: {
            store.multiChoiceQuestionV1Model?.deserializeAnswerJSON(answerJSON);
            break;
          }
          case QuestionType.QuestionType.RANKING: {
            store.rankingQuestionV1Model?.deserializeAnswerJSON(answerJSON);
            break;
          }
          case QuestionType.QuestionType.GRID: {
            store.gridQuestionV1Model?.deserialiseAnswerJson(answerJSON);
            break;
          }
          case QuestionType.QuestionType.GROUP: {
            const groupAnswerV1DTO = <GroupAnswerV1DTO>JSON.parse(answerJSON);
            const groupAnswerV1 = GroupAnswerV1.fromDTO(groupAnswerV1DTO);
            if (JSON.stringify(groupAnswerV1) !== EMPTY_JSON) {
              groupAnswerV1.answer.map((answer) => {
                const questionIndex = store.groupQuestionV1Model.findIndex(
                  (question) =>
                    question.questionCode === answer.questionCode.code,
                );
                if (questionIndex !== -1) {
                  if (
                    answer.answer instanceof
                    GroupAnswerElementV1Enums.Answer.SingleChoice
                  ) {
                    store.groupQuestionV1Model[
                      questionIndex
                    ].deserializeAnswerJSON(
                      JSON.stringify(
                        answer.answer.singleChoiceAnswerAttributes.toDTO(),
                      ),
                    );
                  } else if (
                    answer.answer instanceof
                    GroupAnswerElementV1Enums.Answer.Grid
                  ) {
                    store.groupQuestionV1Model[
                      questionIndex
                    ].deserializeAnswerJSON(
                      JSON.stringify(
                        answer.answer.gridAnswerAttributes.toDTO(),
                      ),
                    );
                  } else if (
                    answer.answer instanceof
                    GroupAnswerElementV1Enums.Answer.MultiChoice
                  ) {
                    store.groupQuestionV1Model[
                      questionIndex
                    ].deserializeAnswerJSON(
                      JSON.stringify(
                        answer.answer.multiChoiceAnswerAttributes.toDTO(),
                      ),
                    );
                  } else if (
                    answer.answer instanceof
                    GroupAnswerElementV1Enums.Answer.OpenEnded
                  ) {
                    store.groupQuestionV1Model[
                      questionIndex
                    ].deserializeAnswerJSON(
                      JSON.stringify(
                        answer.answer.openEndedAnswerAttributes.toDTO(),
                      ),
                    );
                  } else if (
                    answer.answer instanceof
                    GroupAnswerElementV1Enums.Answer.Ranking
                  ) {
                    store.groupQuestionV1Model[
                      questionIndex
                    ].deserializeAnswerJSON(
                      JSON.stringify(
                        answer.answer.rankingQuestionAttributes.toDTO(),
                      ),
                    );
                  }
                }
              });
            }
            break;
          }
          case QuestionType.QuestionType.MESSAGE:
          default: {
            break;
          }
        }
      }
    },
  }));

export const createRespondentQuestionDetailsModel = (
  respondentQuestionDetails: RespondentQuestionDetails,
): Instance<typeof RespondentQuestionDetailsModel> => {
  const commonProps = {
    questionDetailsJSON: respondentQuestionDetails.questionDetailsJSON,
    jsonVersion: respondentQuestionDetails.jsonVersion.version,
    questionType: respondentQuestionDetails.questionType,
    questionId: respondentQuestionDetails.questionId.uuid,
    questionCode: respondentQuestionDetails.questionCode.code,
    isMandatory: respondentQuestionDetails.isMandatory,
    questionText: respondentQuestionDetails.question?.text,
    questionDescription: respondentQuestionDetails.questionDescription?.text,
  };
  switch (respondentQuestionDetails.questionType) {
    case QuestionType.QuestionType.SINGLE_CHOICE: {
      return RespondentQuestionDetailsModel.create({
        ...commonProps,
        singleSelectQuestionV1Model: createSingleSelectQuestionV1Model(),
      });
    }
    case QuestionType.QuestionType.OPEN_ENDED: {
      return RespondentQuestionDetailsModel.create({
        ...commonProps,
        openEndedQuestionV1Model: createOpenEndedQuestionV1Model(),
      });
    }
    case QuestionType.QuestionType.MULTIPLE_CHOICE: {
      return RespondentQuestionDetailsModel.create({
        ...commonProps,
        multiChoiceQuestionV1Model: createMultiChoiceQuestionV1Model(
          undefined,
          [createQuestionOptionsModel()],
        ),
      });
    }
    case QuestionType.QuestionType.GRID: {
      return RespondentQuestionDetailsModel.create({
        ...commonProps,
        gridQuestionV1Model: createGridQuestionV1Model(),
      });
    }
    case QuestionType.QuestionType.RANKING: {
      return RespondentQuestionDetailsModel.create({
        ...commonProps,
        rankingQuestionV1Model: createRankingQuestionV1Model(undefined, [
          createRankingQuestionOptionsModel(),
        ]),
      });
    }
    case QuestionType.QuestionType.GROUP: {
      return RespondentQuestionDetailsModel.create({
        ...commonProps,
        groupQuestionV1Model: [],
      });
    }
    case QuestionType.QuestionType.MESSAGE:
    default: {
      return RespondentQuestionDetailsModel.create({ ...commonProps });
    }
  }
};

import React from "react";
import { SurveyIsClosedLightIllustration } from "../assets/SurveyIsClosedLightIllustration";
import { SurveyIsClosedDarkIllustration } from "../assets/SurveyIsClosedDarkIllustration";
import { ErrorPage } from "@pulse/shared-components";
import { FoundationColorTokens } from "@surya-digital/leo-reactjs-material-ui";

export interface SurveyIsClosedProps {
  isLightMode: boolean;
  surveyIsClosedTitle: string | undefined;
  surveyIsClosedDescription: string | undefined;
  tokens: FoundationColorTokens<string>;
}

export const SurveyIsClosedPage = ({
  isLightMode,
  surveyIsClosedTitle,
  surveyIsClosedDescription,
  tokens,
}: SurveyIsClosedProps): React.ReactElement => {
  const surveyIsClosedIllustrationWidth = "280px";
  if (surveyIsClosedTitle === undefined) {
    console.error("SurveyIsClosedTitle cannot be undefined.");
    return <></>;
  } else if (surveyIsClosedDescription === undefined) {
    console.error("SurveyIsClosedDescription cannot be undefined.");
    return <></>;
  }
  return (
    <ErrorPage
      title={surveyIsClosedTitle}
      description={surveyIsClosedDescription}
      icon={
        isLightMode ? (
          <SurveyIsClosedLightIllustration
            width={surveyIsClosedIllustrationWidth}
            themeTokens={tokens}
          />
        ) : (
          <SurveyIsClosedDarkIllustration
            width={surveyIsClosedIllustrationWidth}
            themeTokens={tokens}
          />
        )
      }
    />
  );
};

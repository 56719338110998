import React, { useState } from "react";
import { TFunction } from "i18next";
import {
  Checkbox,
  Dialog,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { getCheckBoxState } from "@pulse/shared-components";
import { observer } from "mobx-react";

interface RespondentConsentDialogProps {
  isStartSurveyDialogOpen: boolean;
  t: TFunction;
  startSurveyButtonText: string;
  onPrimaryButtonClick: () => void;
  spacing: Spacing;
}

export const RespondentConsentDialog = observer(
  ({
    isStartSurveyDialogOpen,
    onPrimaryButtonClick,
    t,
    startSurveyButtonText,
    spacing,
  }: RespondentConsentDialogProps): React.ReactElement => {
    const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
    return isStartSurveyDialogOpen ? (
      <Dialog
        open={isStartSurveyDialogOpen}
        title={t("welcomePage.startSurveyConsentDialog.title")}
        primaryButtonText={startSurveyButtonText}
        onPrimaryButtonClick={async (): Promise<void> => {
          onPrimaryButtonClick();
          setIsCheckBoxChecked(false);
        }}
        isPrimaryButtonDisabled={!isCheckBoxChecked}
        contentPadding={spacing.spaceLG}
        disableBackdropClick
      >
        <Checkbox
          state={getCheckBoxState(isCheckBoxChecked)}
          onChange={(isMandatory): void => {
            setIsCheckBoxChecked(isMandatory);
          }}
          label={t(
            "welcomePage.startSurveyConsentDialog.iConsentToParticipate",
          )}
        />
      </Dialog>
    ) : (
      <></>
    );
  },
);

import { getEnv, Instance, types } from "mobx-state-tree";
import { AssetType, FileService } from "@eagle-gen/file-service";
import {
  FileIdType,
  RTEAttachmentError,
  RichTextAttachmentConfig,
} from "@surya-digital/leo-reactjs-material-ui";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { RTE_SUPPORTED_FILE_TYPES } from "@pulse/shared-components";
import { getAPIClient } from "../rpcs/APIClient";

export const RTEFileServiceModel = types
  .model("RTEFileServiceModel", {
    isRTEFileServiceError: types.optional(types.boolean, false),
  })
  .views((store) => ({
    get baseURL(): string {
      const BASE_URL = getEnv(store).baseURL;
      if (!BASE_URL) {
        throw Error("API CLient base URL not found");
      }
      return BASE_URL;
    },
  }))
  .actions((store) => ({
    setIsFileUploadError: (isFileUploadError: boolean): void => {
      store.isRTEFileServiceError = isFileUploadError;
    },
  }))
  .actions((store) => ({
    uploadFile: async (file: File): Promise<FileIdType> => {
      const apiClient = getAPIClient(store);
      const temp = new FileService(apiClient, store.baseURL);
      return temp
        .upload(file, AssetType.AssetType.RICH_TEXT_DOCUMENTS)
        .then((uuid) => {
          store.setIsFileUploadError(false);
          return uuid.uuid;
        })
        .catch((e) => {
          console.error(e);
          store.setIsFileUploadError(true);
          return Promise.reject();
        });
    },
    getDownloadURL: async (fileId: FileIdType): Promise<URL> => {
      const apiClient = getAPIClient(store);
      const temp = new FileService(apiClient, store.baseURL);
      return temp
        .getPresignedDownloadURL(new LeoUUID(fileId))
        .then((url) => {
          store.setIsFileUploadError(false);
          return url;
        })
        .catch((e) => {
          console.error(e);
          store.setIsFileUploadError(true);
          return Promise.reject();
        });
    },
    getStaticURL: (fileId: FileIdType): URL => {
      const apiClient = getAPIClient(store);
      const temp = new FileService(apiClient, store.baseURL);
      return temp.getStaticURL(new LeoUUID(fileId));
    },
    onError: (error: RTEAttachmentError): void => {
      console.error(error);
      store.setIsFileUploadError(true);
    },
  }))
  .views((store) => ({
    get richTextAttachmentProps(): RichTextAttachmentConfig {
      return {
        uploadFile: store.uploadFile,
        getStaticURL: store.getStaticURL,
        getDownloadURL: store.getDownloadURL,
        onError: store.onError,
        supportedAttachmentTypes: RTE_SUPPORTED_FILE_TYPES,
      };
    },
  }));

export const createRTEFileServiceModel = (): Instance<
  typeof RTEFileServiceModel
> => {
  return RTEFileServiceModel.create();
};

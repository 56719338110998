import { useNavigate } from "react-router-dom";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { FlowReturn } from "mobx-state-tree/dist/internal";

export const enum Route {
  Root = "/",
  PageNotFound = "/404",
  InternalServerError = "/500",
  NetworkError = "/408",
  PathNotFound = "*",

  // Survey
  SurveyLive = "/live",
  SurveyPreview = "/preview",
  GenericLink = "/generic-link",
}

export interface NavigateToFunctions {
  root: () => void;
  pageNotFound: () => void;
  internalServerError: () => void;
  networkError: () => void;
  reload: () => void;
  parent: () => void;
  surveyLive: (linkId: string) => void;
}

const isUserOnErrorPage = (): boolean => {
  return (
    // window.location.href.substring(window.location.href.length - 4) gets the last 4 characters from the URL to be compared with /500 that is the path for internal server error page.
    // Right now there is no case where /500 would be the last characters for a link, but if in future there exists a case then this check will fail and hence should be updated.
    window.location.href.substring(window.location.href.length - 4) ===
      Route.InternalServerError ||
    window.location.href.substring(window.location.href.length - 4) ===
      Route.PageNotFound
  );
};

export const processSurveyLiveParams = async (
  callback: (surveyLinkId: string, isPreviewURL: boolean) => Promise<void>,
  navigateTo: NavigateToFunctions,
  generateGenericSurveyLink?: (
    surveyId: string,
    projectId: string,
  ) => Promise<FlowReturn<string | undefined>>,
): Promise<void> => {
  const surveyId = new URLSearchParams(location.search).get("surveyId");
  const projectId = new URLSearchParams(location.search).get("projectId");
  if (
    location.pathname.substring(0, 13) === Route.GenericLink &&
    generateGenericSurveyLink &&
    !isUserOnErrorPage()
  ) {
    const link = await generateGenericSurveyLink(
      surveyId ?? EMPTY_CHARACTER,
      projectId ?? EMPTY_CHARACTER,
    );
    if (surveyId && projectId && link) {
      window.location.replace(link);
    } else {
      navigateTo.pageNotFound();
    }
  }
  const surveyLinkId = new URLSearchParams(location.search).get("linkId");
  const isPreviewURL = location.pathname === Route.SurveyPreview;
  if (surveyLinkId === null) {
    if (surveyId === null || projectId === null) {
      navigateTo.pageNotFound();
    }
  } else {
    await callback(surveyLinkId, isPreviewURL);
  }
};

/**
 * This function is designed to handle all navigation within the project.
 * It encapsulates navigation logic and provides a set of functions used for navigation purposes.
 * We should only this function for navigation, and should avoid using the navigation from `react-router-dom`.
 *
 * @returns An object used to navigating to specific routes within the project.
 *
 * @example
 *  // Usage:
 *  const navigateTo = useRouteNavigator();
 *  navigateTo.projectDetails(projectStore.projectName);
 */
export const useRouteNavigator = (): NavigateToFunctions => {
  const navigate = useNavigate();

  const navigateTo: NavigateToFunctions = {
    root: () => navigate(Route.Root),
    pageNotFound: () => navigate(Route.PageNotFound, { replace: true }),
    reload: () => navigate(0),
    surveyLive: (surveylinkId: string) =>
      navigate(`${Route.SurveyLive.replace(":linkId", surveylinkId)}`, {
        replace: true,
      }),
    internalServerError: () => navigate(Route.InternalServerError),
    networkError: () => navigate(Route.NetworkError),
    parent: () => navigate(-1),
  };

  return navigateTo;
};

import {
  BacktrackAndGetPreviousQuestionRPC,
  BacktrackAndGetPreviousQuestionRPCClientImpl,
  GenerateGenericSurveyLinkRPC,
  GenerateGenericSurveyLinkRPCClientImpl,
  GetCurrentQuestionAndSettingsRPC,
  GetCurrentQuestionAndSettingsRPCClientImpl,
  RestartSurveyRPC,
  RestartSurveyRPCClientImpl,
  SaveAndGetNextQuestionRPC,
  SaveAndGetNextQuestionRPCClientImpl,
  SaveDraftRPC,
  SaveDraftRPCClientImpl,
  StartSurveyRPC,
  StartSurveyRPCClientImpl,
  SubmitSurveyRPC,
  SubmitSurveyRPCClientImpl,
  SwitchLanguageRPC,
  SwitchLanguageRPCClientImpl,
} from "@pulse/pulse-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MOCK } from "@pulse/shared-components";
import { MockGetCurrentQuestionsAndSettingsClientImpl } from "./MockGetCurrentQuestionsAndSettingsClientImpl";
import { MockSaveAndGetNextQuestionClientImpl } from "./MockSaveAndGetNextQuestionClientImpl";
import { MockRestartSurveyRPCClientImpl } from "./MockRestartSurveyRPCClientImpl";
import { MockBacktrackAndGetPreviousQuestionClientImpl } from "./MockBacktrackAndGetPreviousQuestionClientImpl";
import { MockStartSurveyRPCClientImpl } from "./MockStartSurveyRPCClientImpl";
import { MockSubmitSurveyRPCClientImpl } from "./MockSubmitSurveyRPCClientImpl";
import { MockSaveDraftClientImpl } from "./MockSaveDraftClientImpl";
import { MockSwitchLanguageRPCClientImpl } from "./MockSwitchLanguageRPCClientImpl";
import { MockGenerateGenericSurveyLinkRPCClientImpl } from "./MockGenerateGenericSurveyLinkRPCClientImpl";

export const useGetCurrentQuestionAndSettingsRPCClientImpl = (
  apiClient: APIClient,
): GetCurrentQuestionAndSettingsRPC => {
  if (MOCK.surveyRespondent) {
    return new MockGetCurrentQuestionsAndSettingsClientImpl();
  } else {
    return new GetCurrentQuestionAndSettingsRPCClientImpl(apiClient);
  }
};

export const useSaveAndGetNextQuestionRPCClientImpl = (
  apiClient: APIClient,
): SaveAndGetNextQuestionRPC => {
  if (MOCK.surveyRespondent) {
    return new MockSaveAndGetNextQuestionClientImpl();
  } else {
    return new SaveAndGetNextQuestionRPCClientImpl(apiClient);
  }
};

export const useBacktrackAndGetPreviousQuestionRPCClientImpl = (
  apiClient: APIClient,
): BacktrackAndGetPreviousQuestionRPC => {
  if (MOCK.surveyRespondent) {
    return new MockBacktrackAndGetPreviousQuestionClientImpl();
  } else {
    return new BacktrackAndGetPreviousQuestionRPCClientImpl(apiClient);
  }
};

export const useRestartSurveyRPCClientImpl = (
  apiClient: APIClient,
): RestartSurveyRPC => {
  if (MOCK.surveyRespondent) {
    return new MockRestartSurveyRPCClientImpl();
  } else {
    return new RestartSurveyRPCClientImpl(apiClient);
  }
};

export const useStartSurveyRPCClientImpl = (
  apiClient: APIClient,
): StartSurveyRPC => {
  if (MOCK.surveyRespondent) {
    return new MockStartSurveyRPCClientImpl();
  } else {
    return new StartSurveyRPCClientImpl(apiClient);
  }
};

export const useSubmitSurveyRPCClientImpl = (
  apiClient: APIClient,
): SubmitSurveyRPC => {
  if (MOCK.surveyRespondent) {
    return new MockSubmitSurveyRPCClientImpl();
  } else {
    return new SubmitSurveyRPCClientImpl(apiClient);
  }
};

export const useSaveDraftRPCClientImpl = (
  apiClient: APIClient,
): SaveDraftRPC => {
  if (MOCK.surveyRespondent) {
    return new MockSaveDraftClientImpl();
  } else {
    return new SaveDraftRPCClientImpl(apiClient);
  }
};

export const useSwitchLanguageRPCClientImpl = (
  apiClient: APIClient,
): SwitchLanguageRPC => {
  if (MOCK.surveyRespondent) {
    return new MockSwitchLanguageRPCClientImpl();
  } else {
    return new SwitchLanguageRPCClientImpl(apiClient);
  }
};

export const useGenerateGenericSurveyLinkRPCClientImpl = (
  apiClient: APIClient,
): GenerateGenericSurveyLinkRPC => {
  if (MOCK.surveyQuestions) {
    return new MockGenerateGenericSurveyLinkRPCClientImpl();
  } else {
    return new GenerateGenericSurveyLinkRPCClientImpl(apiClient);
  }
};

import { Instance } from "mobx-state-tree";
import { useRootStore } from "../../root/store/RootStore";
import { SurveyStore } from "./SurveyStore";
import {
  NetworkingErrorStore,
  NetworkingStore,
} from "@pulse/shared-components";
import { CurrentScreenDetailsStore } from "../../surveyRespondent/store/CurrentScreenDetailsStore";

export const useSurveyStore = (): Instance<typeof SurveyStore> => {
  return useRootStore().surveyStore;
};

export const useNetworkingStore = (): Instance<typeof NetworkingStore> => {
  return useRootStore().networkingStore;
};

export const useNetworkingErrorStore = (): Instance<
  typeof NetworkingErrorStore
> => {
  return useNetworkingStore().errorStore;
};

export const useCurrentScreenDetailsStore = (): Instance<
  typeof CurrentScreenDetailsStore
> => {
  return useSurveyStore().currentScreenDetails;
};

import {
  GridColumnQuestionTypeV1Enum,
  EMPTY_LIST_LENGTH,
  GridQuestionV1Model,
  ValidationErrors,
} from "@pulse/shared-components";
import { Instance } from "mobx-state-tree";

export function validateGridAnswer(
  isMandatory: boolean,
  gridQuestionV1Model: Instance<typeof GridQuestionV1Model>,
): ValidationErrors | null {
  let validationError: ValidationErrors | null = null;
  if (isMandatory) {
    if (gridQuestionV1Model.areAllRowOptionsMandatoryIfRowAttempted) {
      gridQuestionV1Model.gridColumnQuestions.forEach((gridColumnQuestion) => {
        gridQuestionV1Model.rowOptions.forEach((rowOption) => {
          const gridAnswer = gridQuestionV1Model.getGridAnswerModel(
            rowOption.code,
            gridColumnQuestion.columnCode,
            gridColumnQuestion.questionType,
          );
          switch (gridColumnQuestion.questionType) {
            case GridColumnQuestionTypeV1Enum.OpenEnded: {
              if (!gridAnswer.isOpenEndedAnswerCompleted) {
                validationError = ValidationErrors.AllGridCellsNotAnswered;
                return;
              }
              break;
            }
            case GridColumnQuestionTypeV1Enum.MultiChoice: {
              if (!gridAnswer.isMultiSelectAnswerCompleted) {
                validationError = ValidationErrors.AllGridCellsNotAnswered;
                return;
              }
              break;
            }
            case GridColumnQuestionTypeV1Enum.SingleChoice: {
              if (!gridAnswer.isSingleSelectAnswerCompleted) {
                validationError = ValidationErrors.AllGridCellsNotAnswered;
                return;
              }
              break;
            }
          }
        });
      });
    } else {
      gridQuestionV1Model.rowOptions.forEach((rowOption) => {
        let isRowAttempted = false;
        gridQuestionV1Model.gridColumnQuestions.forEach((columnQuestion) => {
          const gridAnswer = gridQuestionV1Model.getGridAnswerModel(
            rowOption.code,
            columnQuestion.columnCode,
            columnQuestion.questionType,
          );
          switch (columnQuestion.questionType) {
            case GridColumnQuestionTypeV1Enum.MultiChoice: {
              if (gridAnswer.isMultiSelectAnswerCompleted) {
                isRowAttempted = true;
                return;
              }
              break;
            }
            case GridColumnQuestionTypeV1Enum.OpenEnded: {
              if (gridAnswer.isOpenEndedAnswerCompleted) {
                isRowAttempted = true;
                return;
              }
              break;
            }
            case GridColumnQuestionTypeV1Enum.SingleChoice: {
              if (gridAnswer.isSingleSelectAnswerCompleted) {
                isRowAttempted = true;
                return;
              }
              break;
            }
          }
        });
        if (!isRowAttempted) {
          validationError = ValidationErrors.AllGridRowsNotAnswered;
          return;
        }
      });
    }
  } else {
    if (gridQuestionV1Model.areAllRowOptionsMandatoryIfRowAttempted) {
      gridQuestionV1Model.rowOptions.forEach((rowOption) => {
        let attemptedColumnQuestionLengthForSingleRow = EMPTY_LIST_LENGTH;
        gridQuestionV1Model.gridColumnQuestions.forEach((columnQuestion) => {
          const gridAnswer = gridQuestionV1Model.getGridAnswerModel(
            rowOption.code,
            columnQuestion.columnCode,
            columnQuestion.questionType,
          );
          switch (columnQuestion.questionType) {
            case GridColumnQuestionTypeV1Enum.MultiChoice: {
              if (gridAnswer.isMultiSelectAnswerCompleted) {
                attemptedColumnQuestionLengthForSingleRow++;
                return;
              }
              break;
            }
            case GridColumnQuestionTypeV1Enum.OpenEnded: {
              if (gridAnswer.isOpenEndedAnswerCompleted) {
                attemptedColumnQuestionLengthForSingleRow++;
                return;
              }
              break;
            }
            case GridColumnQuestionTypeV1Enum.SingleChoice: {
              if (gridAnswer.isSingleSelectAnswerCompleted) {
                attemptedColumnQuestionLengthForSingleRow++;
                return;
              }
              break;
            }
          }
        });
        if (
          attemptedColumnQuestionLengthForSingleRow !== EMPTY_LIST_LENGTH &&
          attemptedColumnQuestionLengthForSingleRow !==
            gridQuestionV1Model.gridColumnQuestions.length
        ) {
          validationError =
            ValidationErrors.AllAttemptedGridRowOptionsNotAnswered;
        }
      });
    }
  }
  return validationError;
}

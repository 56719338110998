import React from "react";
import { Stack, Typography } from "@mui/material";
import { AlertCircle } from "lucide-react";
import {
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { Dialog } from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import { ICON_SIZE, getIconProps } from "@pulse/shared-components";

interface SubmitSurveyDialogProps {
  primaryButtonText: string;
  secondaryButtonText: string | undefined;
  onPrimaryButtonClick: () => void;
  title: string;
  description: string;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  isSubmitSurveyDialogOpen: boolean;
  setIsSubmitSurveyDialogOpen: () => void;
}
export const SubmitSurveyDialog = observer(
  ({
    onPrimaryButtonClick,
    primaryButtonText,
    secondaryButtonText,
    title,
    description,
    spacing,
    typography,
    tokens,
    isSubmitSurveyDialogOpen,
    setIsSubmitSurveyDialogOpen,
  }: SubmitSurveyDialogProps): React.ReactElement => {
    return (
      <Dialog
        open={isSubmitSurveyDialogOpen}
        title={title}
        primaryButtonText={primaryButtonText}
        onPrimaryButtonClick={async (): Promise<void> => {
          onPrimaryButtonClick();
          setIsSubmitSurveyDialogOpen();
        }}
        secondaryButtonText={secondaryButtonText}
        onSecondaryButtonClick={(): void => {
          setIsSubmitSurveyDialogOpen();
        }}
        contentPadding={spacing.spaceLG}
      >
        <Stack
          direction="row"
          width="100%"
          alignItems="flex-start"
          gap={spacing.spaceXS}
          maxWidth={"520px"}
        >
          <AlertCircle {...getIconProps(tokens.icon, ICON_SIZE.default)} />
          <Typography
            sx={{
              ...typography.b2,
              color: tokens.label,
            }}
          >
            {description}
          </Typography>
        </Stack>
      </Dialog>
    );
  },
);

import {
  RichTextAttachmentConfig,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { Dialog } from "@surya-digital/leo-reactjs-material-ui";
import { RichTextEditor } from "@surya-digital/leo-reactjs-remirror";
import { observer } from "mobx-react";
import React from "react";
import {
  DESKTOP_SCREEN_CONDITION,
  RICH_TEXT_DEFAULT_FONT_COLOR,
  RICH_TEXT_MARKDOWN_PROPS,
  richTextTypography,
} from "@pulse/shared-components";
import { useSurveyStore } from "../../root/store/hooks";
import { useMediaQuery } from "@mui/material";
import { SurveySettingsEnums } from "@pulse/pulse-rpcs";
import { RTERegularTextType } from "@pulse/shared-components";

interface SurveyInfoDialogProps {
  isSurveyInfoDialogOpen: boolean;
  setIsSurveyInfoDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fontFamily: string;
  fontScale: SurveySettingsEnums.FontSizeScale.FontSizeScale;
  attachmentConfig: RichTextAttachmentConfig;
}

export const SurveyInfoDialog = observer(
  ({
    isSurveyInfoDialogOpen,
    setIsSurveyInfoDialogOpen,
    fontFamily,
    fontScale,
    attachmentConfig,
  }: SurveyInfoDialogProps): React.ReactElement => {
    const surveyStore = useSurveyStore();
    const spacing = useSpacing();
    const isBigScreen = useMediaQuery(DESKTOP_SCREEN_CONDITION);
    const rtTypography = richTextTypography(
      fontFamily,
      fontScale,
      RICH_TEXT_DEFAULT_FONT_COLOR,
      isBigScreen,
      RTERegularTextType.REGULAR_CUSTOMER_FACING,
    );

    return (
      <Dialog
        open={isSurveyInfoDialogOpen}
        title={surveyStore.surveyInfoDialogTitle}
        secondaryButtonText={surveyStore.surveyInfoDialogPrimaryActionText}
        onSecondaryButtonClick={(): void => {
          setIsSurveyInfoDialogOpen(false);
        }}
        contentPadding={spacing.spaceLG}
        disableBackdropClick
        width="560px"
      >
        <RichTextEditor
          name="Info Dialog"
          typography={rtTypography}
          mode={"preview"}
          borderOnPreview={false}
          initialValue={surveyStore.surveyInfoDialogDescription}
          supports={RICH_TEXT_MARKDOWN_PROPS}
          attachmentConfig={attachmentConfig}
        />
      </Dialog>
    );
  },
);

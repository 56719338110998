import {
  Instance,
  flow,
  getParentOfType,
  getRoot,
  types,
} from "mobx-state-tree";
import {
  RespondentQuestionDetailsModel,
  createRespondentQuestionDetailsModel,
} from "./RespondentQuestionDetailsModel";
import {
  BacktrackAndGetPreviousQuestionRPC,
  CurrentRespondentScreenDetails,
  RespondentQuestionDetails,
  SaveAndGetNextQuestionRPC,
  StartSurveyRPC,
  SubmitSurveyRPC,
} from "@pulse/pulse-rpcs";
import {
  EMPTY_JSON,
  SurveySettingsDialogModel,
  ValidationErrors,
  createSurveySettingsDialogModel,
} from "@pulse/shared-components";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  useBacktrackAndGetPreviousQuestionRPCClientImpl,
  useSaveAndGetNextQuestionRPCClientImpl,
  useStartSurveyRPCClientImpl,
  useSubmitSurveyRPCClientImpl,
} from "../../root/rpcs/RPC";
import { getAPIClient } from "../../root/rpcs/APIClient";
import {
  BacktrackAndGetPreviousQuestionErrors,
  SaveAndGetNextQuestionErrors,
  SurveyRespondentError,
  SurveyStore,
} from "../../root/store/SurveyStore";
import { RootStore } from "../../root/store/RootStore";

export enum CurrentScreenDetails {
  CurrentQuestion = "CURRENT_QUESTION",
  WelcomePage = "WELCOME_PAGE",
  ThankYouPage = "THANK_YOU_PAGE",
}

export enum NextQuestionStatus {
  AllQuestionsAttempted = "ALL_QUESTIONS_ATTEMPTED",
  NextQuestion = "NEXT_QUESTION",
}

export const CurrentScreenDetailsStore = types
  .model("CurrentScreenDetailsStore", {
    currentScreenType: types.enumeration(Object.values(CurrentScreenDetails)),
    respondentQuestionDetails: types.maybeNull(RespondentQuestionDetailsModel),
    answerJson: types.maybeNull(types.string),
    thankYouPageText: types.maybe(types.string),
    welcomePageText: types.maybe(types.string),
    submitConfirmationDialogProperties: types.maybeNull(
      SurveySettingsDialogModel,
    ),
    validationError: types.maybeNull(
      types.enumeration(Object.values(ValidationErrors)),
    ),
    isSubmitConfirmationDialogVisible: types.optional(types.boolean, false),
  })
  .actions((store) => ({
    setRespondentQuestionDetailsModel: (
      respondentQuestionDetails: RespondentQuestionDetails,
    ): void => {
      const currentScreenQuestionDetails = respondentQuestionDetails;
      store.respondentQuestionDetails = createRespondentQuestionDetailsModel(
        currentScreenQuestionDetails,
      );
      store.respondentQuestionDetails.deserializeQuestionJSON();
    },
    clearErrors: (): void => {
      const surveyStore = getParentOfType(store, SurveyStore);
      surveyStore.clearRpcErrors();
      store.validationError = null;
    },
    setValidationError: (validationError: ValidationErrors | null): void => {
      store.validationError = validationError;
    },
    resetSubmitConfirmationDialogProperties: (): void => {
      store.submitConfirmationDialogProperties = null;
    },
    setQuestionDetailsUsingNextQuestionStatus: (
      nextQuestionStatus: SaveAndGetNextQuestionRPC.ResponseEnums.NextQuestionStatus.NextQuestionStatus,
    ): void => {
      if (
        nextQuestionStatus instanceof
        SaveAndGetNextQuestionRPC.ResponseEnums.NextQuestionStatus.NextQuestion
      ) {
        const surveyStore = getParentOfType(store, SurveyStore);
        surveyStore.setIsFirstQuestion(false);
        const questionDetails = nextQuestionStatus.respondentQuestionDetails;
        store.respondentQuestionDetails =
          createRespondentQuestionDetailsModel(questionDetails);
        store.respondentQuestionDetails.deserializeQuestionJSON();

        store.answerJson = nextQuestionStatus.backtrackedAnswerJson;
        store.respondentQuestionDetails.deserializeAnswerJSON();
        surveyStore.setSubmitButtonText(
          nextQuestionStatus.submitConfirmationButtonText?.text,
        );
      } else if (
        nextQuestionStatus instanceof
        SaveAndGetNextQuestionRPC.ResponseEnums.NextQuestionStatus
          .AllQuestionsAttempted
      ) {
        if (
          nextQuestionStatus.nextAction instanceof
          SaveAndGetNextQuestionRPC.ResponseEnums.NextQuestionStatus
            .AllQuestionsAttemptedEnums.NextAction.ThankYouPage
        ) {
          store.thankYouPageText =
            nextQuestionStatus.nextAction.thankYouPageText.text;
        } else if (
          nextQuestionStatus.nextAction instanceof
          SaveAndGetNextQuestionRPC.ResponseEnums.NextQuestionStatus
            .AllQuestionsAttemptedEnums.NextAction.SubmitConfirmation
        ) {
          store.isSubmitConfirmationDialogVisible = true;
          store.submitConfirmationDialogProperties =
            createSurveySettingsDialogModel(
              nextQuestionStatus.nextAction.submitConfirmationDialogProperties,
            );
        }
      }
    },
  }))
  .actions((store) => ({
    setIsSubmitConfirmationDialogVisible: (
      isSubmitConfirmationDialogVisible: boolean,
    ): void => {
      store.isSubmitConfirmationDialogVisible =
        isSubmitConfirmationDialogVisible;
    },
    setCurrentScreenDetails: (
      currentScreenType: CurrentRespondentScreenDetails.CurrentRespondentScreenDetails,
    ): void => {
      if (
        currentScreenType instanceof
        CurrentRespondentScreenDetails.CurrentQuestion
      ) {
        store.currentScreenType = CurrentScreenDetails.CurrentQuestion;
        const surveyStore = getParentOfType(store, SurveyStore);
        const currentScreenQuestionDetails =
          currentScreenType.respondentQuestionDetails;
        store.respondentQuestionDetails = createRespondentQuestionDetailsModel(
          currentScreenQuestionDetails,
        );
        store.respondentQuestionDetails.deserializeQuestionJSON();
        if (currentScreenType.savedAnswerJson !== null) {
          store.answerJson = currentScreenType.savedAnswerJson;
          store.respondentQuestionDetails.deserializeAnswerJSON();
        }
        surveyStore.setIsFirstQuestion(currentScreenType.isFirstQuestion);
      } else if (
        currentScreenType instanceof CurrentRespondentScreenDetails.WelcomePage
      ) {
        store.currentScreenType = CurrentScreenDetails.WelcomePage;
        store.welcomePageText = currentScreenType.welcomePageText?.text;
      } else if (
        currentScreenType instanceof CurrentRespondentScreenDetails.ThankYouPage
      ) {
        store.currentScreenType = CurrentScreenDetails.ThankYouPage;
        store.thankYouPageText = currentScreenType.thankYouPageText.text;
      }
    },
    submitSurvey: flow(function* (surveyLinkId: string) {
      store.clearErrors();
      const surveyStore = getParentOfType(store, SurveyStore);
      surveyStore.setIsSurveyDataReceived(false);
      surveyStore.setIsNetworkCallMade(true);
      try {
        const request = new SubmitSurveyRPC.Request(new LeoUUID(surveyLinkId));
        const apiClient = getAPIClient(store);
        const {
          response,
          error,
        }: {
          response?: SubmitSurveyRPC.Response;
          error?: SubmitSurveyRPC.Errors.Errors;
        } = yield useSubmitSurveyRPCClientImpl(apiClient).execute(request);
        if (response) {
          store.thankYouPageText = response.thankYouPageText.text;
          surveyStore.setProgressPercentage(
            response.progressPercentage.percentageValue,
          );
        }
        if (error) {
          switch (error.code) {
            case SurveyRespondentError.InvalidSurveyLinkId:
              surveyStore.setRpcErrors(
                SurveyRespondentError.InvalidSurveyLinkId,
              );
              break;
            case SurveyRespondentError.SurveyIsClosed:
              if (error instanceof SubmitSurveyRPC.Errors.SurveyIsClosed) {
                surveyStore.setSurveyIsClosedTitleText(
                  error.surveyIsClosedTitleText.text,
                );
                surveyStore.setSurveyIsClosedDescriptionText(
                  error.surveyIsClosedDescriptionText.text,
                );
              }
              surveyStore.setRpcErrors(SurveyRespondentError.SurveyIsClosed);
              break;
            default:
              surveyStore.clearRpcErrors();
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
      surveyStore.setIsRPCLoading(false);
      surveyStore.setIsSurveyDataReceived(true);
      surveyStore.setIsNetworkCallMade(false);
    }),
    startSurvey: flow(function* (surveyLinkId: string) {
      store.clearErrors();
      const surveyStore = getParentOfType(store, SurveyStore);
      surveyStore.setIsRPCLoading(true);
      surveyStore.setIsFirstQuestion(true);
      try {
        const request = new StartSurveyRPC.Request(new LeoUUID(surveyLinkId));
        const apiClient = getAPIClient(store);
        const {
          response,
          error,
        }: {
          response?: StartSurveyRPC.Response;
          error?: StartSurveyRPC.Errors.Errors;
        } = yield useStartSurveyRPCClientImpl(apiClient).execute(request);
        if (response) {
          if (
            response.questiondetails instanceof
            StartSurveyRPC.ResponseEnums.Questiondetails.FirstQuestion
          ) {
            store.respondentQuestionDetails =
              createRespondentQuestionDetailsModel(
                response.questiondetails.respondentQuestionDetails,
              );
            store.respondentQuestionDetails.deserializeQuestionJSON();
            surveyStore.setProgressPercentage(
              response.questiondetails.progressPercentage.percentageValue,
            );
          } else if (
            response.questiondetails instanceof
            StartSurveyRPC.ResponseEnums.Questiondetails.ThankYouPage
          ) {
            store.thankYouPageText =
              response.questiondetails.thankYouPageText.text;
            surveyStore.setProgressPercentage(
              response.questiondetails.progressPercentage.percentageValue,
            );
          }
        }
        if (error) {
          switch (error.code) {
            case SurveyRespondentError.InvalidSurveyLinkId:
              surveyStore.setRpcErrors(
                SurveyRespondentError.InvalidSurveyLinkId,
              );
              break;
            case SurveyRespondentError.SurveyAlreadySubmitted:
              if (
                error instanceof StartSurveyRPC.Errors.SurveyAlreadySubmitted
              ) {
                store.thankYouPageText = error.thankYouPageText.text;
              } else {
                console.error(
                  error,
                  "Error must be instance of SurveyAlreadySubmitted.",
                );
              }
              surveyStore.setRpcErrors(
                SurveyRespondentError.SurveyAlreadySubmitted,
              );
              break;
            case SurveyRespondentError.SurveyIsClosed:
              if (error instanceof StartSurveyRPC.Errors.SurveyIsClosed) {
                surveyStore.setSurveyIsClosedTitleText(
                  error.surveyIsClosedTitleText.text,
                );
                surveyStore.setSurveyIsClosedDescriptionText(
                  error.surveyIsClosedDescriptionText.text,
                );
              }
              surveyStore.setRpcErrors(SurveyRespondentError.SurveyIsClosed);
              break;
            default:
              surveyStore.clearRpcErrors();
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
      surveyStore.setIsRPCLoading(false);
    }),
    saveAndGetNextQuestion: flow(function* saveAndGetNextQuestion(
      surveyLinkId: string,
    ) {
      store.clearErrors();
      const surveyStore = getParentOfType(store, SurveyStore);
      const answerJSON = store.respondentQuestionDetails?.serializedAnswerJSON;
      store.respondentQuestionDetails?.validateAnswer(answerJSON);
      if (store.validationError === null && answerJSON) {
        surveyStore.setIsSurveyDataReceived(false);
        surveyStore.setIsNetworkCallMade(true);
        try {
          const request = new SaveAndGetNextQuestionRPC.Request(
            new LeoUUID(store.respondentQuestionDetails?.questionId),
            new LeoUUID(surveyLinkId),
            answerJSON,
          );
          const apiClient = getAPIClient(store);
          const {
            response,
            error,
          }: {
            response?: SaveAndGetNextQuestionRPC.Response;
            error?: SaveAndGetNextQuestionRPC.Errors.Errors;
          } =
            yield useSaveAndGetNextQuestionRPCClientImpl(apiClient).execute(
              request,
            );

          if (response) {
            store.setQuestionDetailsUsingNextQuestionStatus(
              response.nextQuestionStatus,
            );
            surveyStore.setProgressPercentage(
              response.progressPercentage.percentageValue,
            );
          } else if (error) {
            switch (error.code) {
              case SurveyRespondentError.InvalidSurveyLinkId:
                surveyStore.setRpcErrors(
                  SurveyRespondentError.InvalidSurveyLinkId,
                );
                break;
              case SurveyRespondentError.SurveyAlreadySubmitted:
                if (
                  error instanceof
                  SaveAndGetNextQuestionRPC.Errors.SurveyAlreadySubmitted
                ) {
                  store.thankYouPageText = error.thankYouPageText.text;
                } else {
                  console.error(
                    error,
                    "Error must be instance of SurveyAlreadySubmitted.",
                  );
                }
                surveyStore.setRpcErrors(
                  SurveyRespondentError.SurveyAlreadySubmitted,
                );
                break;
              case SurveyRespondentError.SurveyIsClosed:
                if (
                  error instanceof
                  SaveAndGetNextQuestionRPC.Errors.SurveyIsClosed
                ) {
                  surveyStore.setSurveyIsClosedTitleText(
                    error.surveyIsClosedTitleText.text,
                  );
                  surveyStore.setSurveyIsClosedDescriptionText(
                    error.surveyIsClosedDescriptionText.text,
                  );
                }
                surveyStore.setRpcErrors(SurveyRespondentError.SurveyIsClosed);
                break;
              case SaveAndGetNextQuestionErrors.InvalidAnswerJson:
                surveyStore.setRpcErrors(
                  SaveAndGetNextQuestionErrors.InvalidAnswerJson,
                );
                break;
              case SaveAndGetNextQuestionErrors.InvalidQuestionId:
                surveyStore.setRpcErrors(
                  SaveAndGetNextQuestionErrors.InvalidQuestionId,
                );
                break;
              default:
                surveyStore.clearRpcErrors();
                break;
            }
          }
        } catch (e) {
          if (e instanceof Error) {
            const rootStore = getRoot<typeof RootStore>(store);
            rootStore.networkingStore.errorStore.setLeoError(e);
          } else {
            console.error(`Unhandled error occured: ${e}`);
          }
        } finally {
          surveyStore.setIsRPCLoading(false);
          surveyStore.setIsSurveyDataReceived(true);
          surveyStore.setIsNetworkCallMade(false);
        }
      }
    }),
    backtrackAndGetPreviousQuestion: flow(
      function* backtrackAndGetPreviousQuestion(surveyLinkId: string) {
        store.clearErrors();
        const surveyStore = getParentOfType(store, SurveyStore);
        surveyStore.setIsSurveyDataReceived(false);
        surveyStore.setIsNetworkCallMade(true);
        surveyStore.setSubmitButtonText(undefined);
        const partialAnswerJSON =
          store.respondentQuestionDetails?.serializedAnswerJSON ?? EMPTY_JSON;
        try {
          const request = new BacktrackAndGetPreviousQuestionRPC.Request(
            new LeoUUID(store.respondentQuestionDetails?.questionId),
            new LeoUUID(surveyLinkId),
            partialAnswerJSON,
          );
          const apiClient = getAPIClient(store);
          const {
            response,
            error,
          }: {
            response?: BacktrackAndGetPreviousQuestionRPC.Response;
            error?: BacktrackAndGetPreviousQuestionRPC.Errors.Errors;
          } =
            yield useBacktrackAndGetPreviousQuestionRPCClientImpl(
              apiClient,
            ).execute(request);

          if (response) {
            store.respondentQuestionDetails =
              createRespondentQuestionDetailsModel(
                response.previousQuestionDetails,
              );
            store.respondentQuestionDetails.deserializeQuestionJSON();
            surveyStore.setIsFirstQuestion(response.isFirstQuestion);
            surveyStore.setProgressPercentage(
              response.progressPercentage.percentageValue,
            );

            store.answerJson = response.answerJson;
            store.respondentQuestionDetails.deserializeAnswerJSON();
          } else if (error) {
            switch (error.code) {
              case SurveyRespondentError.InvalidSurveyLinkId:
                surveyStore.setRpcErrors(
                  SurveyRespondentError.InvalidSurveyLinkId,
                );
                break;
              case SurveyRespondentError.SurveyIsClosed:
                surveyStore.setRpcErrors(SurveyRespondentError.SurveyIsClosed);
                break;
              case SurveyRespondentError.SurveyAlreadySubmitted:
                if (
                  error instanceof
                  BacktrackAndGetPreviousQuestionRPC.Errors
                    .SurveyAlreadySubmitted
                ) {
                  store.thankYouPageText = error.thankYouPageText.text;
                } else {
                  console.error(
                    error,
                    "Error must be instance of SurveyAlreadySubmitted.",
                  );
                }
                surveyStore.setRpcErrors(
                  SurveyRespondentError.SurveyAlreadySubmitted,
                );
                break;
              case SaveAndGetNextQuestionErrors.InvalidQuestionId:
                surveyStore.setRpcErrors(
                  SaveAndGetNextQuestionErrors.InvalidQuestionId,
                );
                break;
              case BacktrackAndGetPreviousQuestionErrors.PreviousQuestionNotFound:
                surveyStore.setRpcErrors(
                  BacktrackAndGetPreviousQuestionErrors.PreviousQuestionNotFound,
                );
                break;
              default:
                surveyStore.clearRpcErrors();
                break;
            }
          }
        } catch (e) {
          if (e instanceof Error) {
            const rootStore = getRoot<typeof RootStore>(store);
            rootStore.networkingStore.errorStore.setLeoError(e);
          } else {
            console.error(`Unhandled error occured: ${e}`);
          }
        } finally {
          surveyStore.setIsRPCLoading(false);
          surveyStore.setIsSurveyDataReceived(true);
          surveyStore.setIsNetworkCallMade(false);
        }
      },
    ),
  }));

export const createCurrentScreenDetailsStore = (): Instance<
  typeof CurrentScreenDetailsStore
> => {
  return CurrentScreenDetailsStore.create({
    currentScreenType: CurrentScreenDetails.CurrentQuestion,
    answerJson: EMPTY_JSON,
  });
};

import React from "react";
import { NavigateToFunctions } from "../routes/RoutesHelper";
import { NetworkingError } from "@pulse/shared-components";

export const navigateOrGetNetworkErrorPage = (
  error: NetworkingError | null,
  navigateTo: NavigateToFunctions,
): React.ReactElement | void => {
  if (error) {
    switch (error) {
      case NetworkingError.InternalServerError:
      case NetworkingError.InvalidRequest:
      case NetworkingError.RPCError:
        navigateTo.internalServerError();
        break;
      case NetworkingError.Unauthorized:
      case NetworkingError.PageNotFound:
        navigateTo.pageNotFound();
        break;
      case NetworkingError.Unauthenticated:
        console.error("Survey respondent cannot have Unauthenticated error.");
        break;
      case NetworkingError.TimeOut:
      case NetworkingError.NetworkError:
        navigateTo.networkError();
        break;
    }
  }
};

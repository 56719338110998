import { Instance, types } from "mobx-state-tree";
import { PrimaryColorTokens } from "@pulse/pulse-rpcs";

export const PrimaryColorTokensModel = types.model("PrimaryColorTokensModel", {
  primary100Code: types.string,
  primary200Code: types.string,
  primary300Code: types.string,
  primary400Code: types.string,
  primary500Code: types.string,
});

export const createPrimaryColorTokensModel = ({
  primary100Code,
  primary200Code,
  primary300Code,
  primary400Code,
  primary500Code,
}: PrimaryColorTokens): Instance<typeof PrimaryColorTokensModel> => {
  return PrimaryColorTokensModel.create({
    primary100Code: primary100Code.code,
    primary200Code: primary200Code.code,
    primary300Code: primary300Code.code,
    primary400Code: primary400Code.code,
    primary500Code: primary500Code.code,
  });
};

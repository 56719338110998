import React from "react";
import { observer } from "mobx-react";
import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  RadioButtonColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { AlertCircle } from "lucide-react";
import {
  ICON_SIZE,
  getIconProps,
  getRadioButtonStyleProps,
} from "@pulse/shared-components";
import {
  NavigateToFunctions,
  processSurveyLiveParams,
} from "../../../routes/RoutesHelper";
import { SurveyStore } from "../../root/store/SurveyStore";
import { Instance } from "mobx-state-tree";

interface ChangeLanguageDialogProps {
  isChangeLanguageDialogOpen: boolean;
  setIsChangeLanguageDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  surveyStore: Instance<typeof SurveyStore>;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  radioButtonTokens: RadioButtonColorTokens<string>;
  navigateTo: NavigateToFunctions;
}

export const ChangeLanguageDialog = observer(
  ({
    isChangeLanguageDialogOpen,
    setIsChangeLanguageDialogOpen,
    surveyStore,
    spacing,
    tokens,
    typography,
    radioButtonTokens,
    navigateTo,
  }: ChangeLanguageDialogProps): React.ReactElement => {
    const radioProps = getRadioButtonStyleProps(
      tokens,
      radioButtonTokens,
      spacing,
      // Only horizontal padding is provided here because the vertical padding is given to the radio button as a whole.
      // Adding vertical padding here would add padding only to the radio button icon and not the text.
      `0px ${spacing.spaceXS}`,
    );

    return (
      <Dialog
        open={isChangeLanguageDialogOpen}
        title={
          surveyStore.surveySettings?.languageSelectionDialogTexts?.titleText
        }
        primaryButtonText={
          surveyStore.surveySettings?.languageSelectionDialogTexts
            ?.primaryActionText
        }
        width="560px"
        contentPadding={spacing.spaceLG}
        onPrimaryButtonClick={async (): Promise<void> => {
          if (surveyStore.tempSelectedSurveyLanguageCode) {
            setIsChangeLanguageDialogOpen(false);
            const languageId = surveyStore.getTempSelectedSurveyLanguageId;
            await processSurveyLiveParams(async (surveyLinkId) => {
              if (languageId) {
                surveyStore.switchSurveyLanguage(languageId, surveyLinkId);
              } else {
                console.error(
                  "The temp selected language Id cannot be undefined.",
                );
              }
            }, navigateTo);
          } else {
            console.error(
              "The temp selected language code cannot be undefined.",
            );
          }
        }}
        secondaryButtonText={
          surveyStore.surveySettings?.languageSelectionDialogTexts
            .secondaryActionText
        }
        onSecondaryButtonClick={(): void => {
          setIsChangeLanguageDialogOpen(false);
        }}
        disableBackdropClick={true}
      >
        <Stack gap={spacing.spaceLG} width="100%">
          <Stack spacing={spacing.spaceXS} direction="row">
            <AlertCircle {...getIconProps(tokens.icon, ICON_SIZE.default)} />
            <Typography
              sx={{
                ...typography.b2,
                color: tokens.label,
                width: "492px",
              }}
            >
              {
                surveyStore.surveySettings?.languageSelectionDialogTexts
                  ?.descriptionText
              }
            </Typography>
          </Stack>
          <RadioGroup
            value={surveyStore.tempSelectedSurveyLanguageCode}
            onChange={(_, value) =>
              surveyStore.setTempSelectedSurveyLanguageCode(value)
            }
          >
            {surveyStore.surveySettings?.languageList.map((surveyLanguage) => (
              <Stack py={spacing.spaceXS} key={surveyLanguage.languageId}>
                <FormControlLabel
                  sx={{ color: tokens.label }}
                  control={<Radio {...radioProps} />}
                  value={surveyLanguage.languageCode}
                  label={
                    <Typography
                      {...typography.b1}
                      width="488px"
                      color={tokens.label}
                      sx={{ wordBreak: "break-word" }}
                    >
                      {surveyLanguage.languageCode}
                    </Typography>
                  }
                />
              </Stack>
            ))}
          </RadioGroup>
        </Stack>
      </Dialog>
    );
  },
);

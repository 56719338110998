import React, { useEffect, useState } from "react";
import { useSurveyStore } from "../../root/store/hooks";
import {
  processSurveyLiveParams,
  useRouteNavigator,
} from "../../../routes/RoutesHelper";
import { observer } from "mobx-react";
import { TOTAL_COUNTDOWN_TIME_IN_SEC } from "@pulse/shared-components";
import { COUNTDOWN_INTERVAL_IN_MS } from "@pulse/shared-components";
import { END_OF_COUNTDOWN } from "@pulse/shared-components";
import { ZERO_VALUE } from "@pulse/shared-components";
import {
  AlertCircle,
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Box, Stack, Typography } from "@mui/material";
import { ICON_SIZE } from "@pulse/shared-components";

interface ResetSurveyDialogProps {
  isRestartSurveyDialogOpen: boolean;
  setIsRestartSurveyDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
}

export const RestartSurveyDialog = observer(
  ({
    isRestartSurveyDialogOpen,
    setIsRestartSurveyDialogOpen,
    spacing,
    tokens,
    typography,
  }: ResetSurveyDialogProps): React.ReactElement => {
    const [countDownValue, setCountDownValue] = useState(
      TOTAL_COUNTDOWN_TIME_IN_SEC,
    );
    const surveyStore = useSurveyStore();
    const navigateTo = useRouteNavigator();

    useEffect(() => {
      const interval = setInterval(() => {
        setCountDownValue(countDownValue - 1);
      }, COUNTDOWN_INTERVAL_IN_MS);
      if (countDownValue === END_OF_COUNTDOWN) {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }, [countDownValue]);

    return (
      <Dialog
        isPrimaryButtonDisabled={countDownValue !== ZERO_VALUE}
        open={isRestartSurveyDialogOpen}
        title={surveyStore.restartSurveyTexts?.titleText}
        primaryButtonText={
          countDownValue === END_OF_COUNTDOWN
            ? surveyStore.restartSurveyTexts?.primaryActionText
            : surveyStore.restartSurveyTexts?.primaryActionText +
              ` (${countDownValue})`
        }
        secondaryButtonText={
          surveyStore.restartSurveyTexts?.secondaryActionText
        }
        onSecondaryButtonClick={() => {
          setIsRestartSurveyDialogOpen(false);
        }}
        onPrimaryButtonClick={async (): Promise<void> => {
          setIsRestartSurveyDialogOpen(false);
          await processSurveyLiveParams(async (surveyLinkId) => {
            await surveyStore.restartSurvey(surveyLinkId);
          }, navigateTo);
        }}
      >
        <Stack
          direction="row"
          width="100%"
          alignItems="flex-start"
          gap={spacing.spaceXS}
          maxWidth="520px"
        >
          <Box minWidth={ICON_SIZE.default}>
            <AlertCircle
              color={tokens.iconError}
              width={ICON_SIZE.default}
              height={ICON_SIZE.default}
            />
          </Box>
          <Typography {...typography.b2} color={tokens.labelError}>
            {surveyStore.restartSurveyTexts?.descriptionText}
          </Typography>
        </Stack>
      </Dialog>
    );
  },
);

import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import {
  getStringDefaultIfUndefined,
  FeatureInDevelopmentSnackbar,
  NetworkingError,
  RootContainer,
  SurveyInformationPane,
  SuccessfulDialogComponent,
  RPC_LOADER_DELAYED_TIME_IN_MS,
  isBrowserLightMode,
  PRIMARY_FONT,
  FontSizeScale,
  EMPTY_CHARACTER,
  SurveySectionComponent,
  MandatoryErrorSnackbar,
} from "@pulse/shared-components";
import {
  useCurrentScreenDetailsStore,
  useNetworkingErrorStore,
  useNetworkingStore,
  useSurveyStore,
} from "../store/hooks";
import {
  processSurveyLiveParams,
  useRouteNavigator,
} from "../../../routes/RoutesHelper";
import {
  BacktrackAndGetPreviousQuestionErrors,
  SaveAndGetNextQuestionErrors,
  SurveyRespondentError,
  SwitchSurveyLanguageErrors,
} from "../store/SurveyStore";
import { navigateOrGetNetworkErrorPage } from "../../../utils/navigateOrGetNetworkErrorPage";
import {
  LoadingIndicator,
  useFoundationColorTokens,
  useRadioButtonColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { RestartSurveyDialog } from "../../surveyRespondent/components/RestartSurveyDialog";
import { RespondentConsentDialog } from "../../surveyRespondent/components/RespondentConsentDialog";
import { useTranslation } from "react-i18next";
import { SubmitSurveyDialog } from "../../surveyRespondent/components/SubmitSurveyDialog";
import { SurveyInfoDialog } from "../../surveyRespondent/components/SurveyInfoDialog";
import { ChangeLanguageDialog } from "../../surveyRespondent/components/ChangeLanguageDialog";
import { SurveyIsClosedPage } from "./SurveyIsClosedPage";

export const RootPage = observer((): React.ReactElement => {
  const surveyStore = useSurveyStore();
  const errorStore = useNetworkingErrorStore();
  const networkingStore = useNetworkingStore();
  const currentScreenDetailsStore = useCurrentScreenDetailsStore();

  const navigateTo = useRouteNavigator();
  const { t } = useTranslation();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const themedImageLight =
    surveyStore.surveySettings?.themedImage?.lightImageURL;
  const themedImageDark = surveyStore.surveySettings?.themedImage?.darkImageURL;
  const selectedLanguageCode = surveyStore.surveySettings?.selectedLanguageCode;
  const tokens = useFoundationColorTokens();
  const radioButtonTokens = useRadioButtonColorTokens();
  const [isRestartSurveyDialogOpen, setIsRestartSurveyDialogOpen] =
    useState(false);
  const [isSurveyInfoDialogOpen, setIsSurveyInfoDialogOpen] = useState(false);
  const [isStartSurveyDialogOpen, setIsStartSurveyDialogOpen] = useState(false);
  const [isChangeLanguageDialogOpen, setIsChangeLanguageDialogOpen] =
    useState(false);
  const [
    isSurveySubmittedErrorDialogOpen,
    setIsSurveySubmittedErrorDialogOpen,
  ] = useState(false);
  const spacing = useSpacing();
  const typography = useTypography();
  const surveySectionChildComponentRef = useRef<HTMLDivElement>(null);
  const isLightMode = isBrowserLightMode();

  useEffect(() => {
    navigateOrGetNetworkErrorPage(errorStore.error, navigateTo);
    processSurveyLiveParams(async () => {}, navigateTo);
    if (!surveyStore.isWelcomePageShown && surveyStore.isFirstQuestion) {
      if (
        surveyStore.surveySettings?.isRespondentConsentRequired &&
        !surveyStore.hasRespondentConsented
      ) {
        setIsStartSurveyDialogOpen(true);
      } else if (
        surveyStore.surveySettings?.isLanguageSelectionDisplayedOnStart &&
        !surveyStore.isSingleLanguageAvailable
      ) {
        setIsChangeLanguageDialogOpen(true);
      }
    }
    return () => {
      surveyStore.setIsRPCLoading(true);
      errorStore.removeError();
      surveyStore.setIsRPCLoading(false);
    };
  }, []);

  const clearError = (): void => {
    surveyStore.clearRpcErrors();
    errorStore.removeError();
  };

  useEffect(() => {
    if (errorStore.error) {
      switch (errorStore.error) {
        case NetworkingError.PageNotFound:
          navigateTo.pageNotFound();
          break;
        default:
          navigateTo.internalServerError();
      }
      errorStore.removeError();
    }
  }, [errorStore.error]);

  // This effect is added here to make sure that the component doesn't infinitely re-render on change of the states.
  useEffect(() => {
    if (surveyStore.isSurveyAlreadySubmitted) {
      setIsSurveySubmittedErrorDialogOpen(true);
    }
  }, [surveyStore.rpcError]);

  if (surveyStore.rpcError) {
    switch (surveyStore.rpcError) {
      case NetworkingError.InternalError: {
        clearError();
        navigateTo.internalServerError();
        break;
      }
      case NetworkingError.PageNotFound:
      case SurveyRespondentError.InvalidSurveyLinkId: {
        clearError();
        navigateTo.pageNotFound();
        break;
      }
      case SurveyRespondentError.SurveyIsClosed: {
        return (
          <SurveyIsClosedPage
            isLightMode={isLightMode}
            surveyIsClosedTitle={surveyStore.surveyIsClosedTitleText}
            surveyIsClosedDescription={
              surveyStore.surveyIsClosedDescriptionText
            }
            tokens={tokens}
          />
        );
      }
      case SaveAndGetNextQuestionErrors.InvalidAnswerJson: {
        clearError();
        navigateTo.internalServerError();
        break;
      }
      case BacktrackAndGetPreviousQuestionErrors.PreviousQuestionNotFound:
      case SwitchSurveyLanguageErrors.InvalidLanguageId:
      case SaveAndGetNextQuestionErrors.InvalidQuestionId: {
        clearError();
        navigateTo.internalServerError();
        break;
      }
    }
  }

  const setDelayedRPCLoading = (): void => {
    setTimeout(() => {
      if (
        !surveyStore.isSurveyDataReceived &&
        !surveyStore.currentScreenDetails.validationError
      ) {
        surveyStore.setIsRPCLoading(true);
      }
    }, RPC_LOADER_DELAYED_TIME_IN_MS);
  };

  const callSaveAndGetNextQuestionRPC = async (): Promise<void> => {
    currentScreenDetailsStore.resetSubmitConfirmationDialogProperties();
    await processSurveyLiveParams(async (surveyLinkId) => {
      surveyStore.currentScreenDetails.saveAndGetNextQuestion(surveyLinkId);
      setDelayedRPCLoading();
    }, navigateTo);
  };

  const callSaveDraftRPC = async (): Promise<void> => {
    currentScreenDetailsStore.resetSubmitConfirmationDialogProperties();
    await processSurveyLiveParams(async (surveyLinkId) => {
      surveyStore.saveDraft(surveyLinkId);
      setDelayedRPCLoading();
    }, navigateTo);
  };

  return (
    <RootContainer>
      {isRestartSurveyDialogOpen && (
        <RestartSurveyDialog
          isRestartSurveyDialogOpen={isRestartSurveyDialogOpen}
          setIsRestartSurveyDialogOpen={setIsRestartSurveyDialogOpen}
          spacing={spacing}
          tokens={tokens}
          typography={typography}
        />
      )}
      <ChangeLanguageDialog
        isChangeLanguageDialogOpen={isChangeLanguageDialogOpen}
        setIsChangeLanguageDialogOpen={setIsChangeLanguageDialogOpen}
        surveyStore={surveyStore}
        spacing={spacing}
        tokens={tokens}
        typography={typography}
        radioButtonTokens={radioButtonTokens}
        navigateTo={navigateTo}
      />
      <SurveyInfoDialog
        isSurveyInfoDialogOpen={isSurveyInfoDialogOpen}
        setIsSurveyInfoDialogOpen={setIsSurveyInfoDialogOpen}
        fontFamily={surveyStore.surveySettings?.font ?? PRIMARY_FONT}
        fontScale={
          surveyStore.surveySettings?.fontSizeScale ??
          FontSizeScale.DEFAULT_SCALE
        }
        attachmentConfig={
          surveyStore.rteFileServiceStore.richTextAttachmentProps
        }
      />
      <SuccessfulDialogComponent
        showSuccessfulDialogComponent={surveyStore.isSaveDraftDialogShown}
        title={surveyStore.saveDraftTexts?.titleText}
        description={surveyStore.saveDraftTexts?.descriptionText}
        secondaryButtonText={surveyStore.saveDraftTexts?.primaryActionText}
        onSecondaryButtonClick={surveyStore.resetSaveDraftDialog}
        descriptionTextWidth="472px"
      />
      <SuccessfulDialogComponent
        showSuccessfulDialogComponent={isSurveySubmittedErrorDialogOpen}
        title={
          surveyStore.surveySettings?.surveyAlreadySubmittedDialogTexts
            ?.titleText
        }
        description={
          surveyStore.surveySettings?.surveyAlreadySubmittedDialogTexts
            ?.descriptionText
        }
        secondaryButtonText={
          surveyStore.surveySettings?.surveyAlreadySubmittedDialogTexts
            ?.primaryActionText
        }
        onSecondaryButtonClick={() => {
          setIsSurveySubmittedErrorDialogOpen(false);
          clearError();
        }}
        descriptionTextWidth="472px"
      />
      {surveyStore.surveySettings?.startButtonText && (
        <RespondentConsentDialog
          isStartSurveyDialogOpen={isStartSurveyDialogOpen}
          t={t}
          startSurveyButtonText={surveyStore.surveySettings.startButtonText}
          onPrimaryButtonClick={(): void => {
            if (!surveyStore.isFirstQuestion) {
              processSurveyLiveParams(async (surveyLinkId) => {
                await surveyStore.currentScreenDetails.startSurvey(
                  surveyLinkId,
                );
              }, navigateTo);
              if (!surveyStore.doesStoreContainErrors) {
                surveyStore.setHasUserConsentedToTrue();
              }
            }
            if (
              surveyStore.surveySettings?.isLanguageSelectionDisplayedOnStart &&
              !surveyStore.isSingleLanguageAvailable
            ) {
              setIsChangeLanguageDialogOpen(true);
            }
            setIsStartSurveyDialogOpen(false);
          }}
          spacing={spacing}
        />
      )}
      {currentScreenDetailsStore.submitConfirmationDialogProperties
        ?.primaryActionText && (
        <SubmitSurveyDialog
          isSubmitSurveyDialogOpen={
            currentScreenDetailsStore.isSubmitConfirmationDialogVisible
          }
          setIsSubmitSurveyDialogOpen={() => {
            currentScreenDetailsStore.setIsSubmitConfirmationDialogVisible(
              false,
            );
          }}
          primaryButtonText={
            currentScreenDetailsStore.submitConfirmationDialogProperties
              .primaryActionText
          }
          secondaryButtonText={
            currentScreenDetailsStore.submitConfirmationDialogProperties
              .secondaryActionText
          }
          onPrimaryButtonClick={(): void => {
            processSurveyLiveParams(async (surveyLinkId) => {
              surveyStore.currentScreenDetails.submitSurvey(surveyLinkId);
              setDelayedRPCLoading();
            }, navigateTo);
          }}
          title={
            currentScreenDetailsStore.submitConfirmationDialogProperties
              .titleText
          }
          description={
            currentScreenDetailsStore.submitConfirmationDialogProperties
              .descriptionText
          }
          spacing={spacing}
          tokens={tokens}
          typography={typography}
        />
      )}
      {surveyStore.isGetCurrentQuestionAndSettingsRPCRPCLoading ? (
        <LoadingIndicator
          isLoading={surveyStore.isGetCurrentQuestionAndSettingsRPCRPCLoading}
          variant="overlay"
          iconColor={"icon"}
        />
      ) : (
        <SurveySectionComponent
          fontFamily={surveyStore.surveySettings?.font ?? PRIMARY_FONT}
          fontScale={
            surveyStore.surveySettings?.fontSizeScale ??
            FontSizeScale.DEFAULT_SCALE
          }
          thankYouPageText={surveyStore.currentScreenDetails.thankYouPageText}
          isThankYouPageShown={surveyStore.isThankyouPageShown}
          logoURL={
            isBrowserLightMode()
              ? themedImageLight
              : themedImageDark
                ? themedImageDark
                : themedImageLight
          }
          languageCode={getStringDefaultIfUndefined(selectedLanguageCode)}
          isProgressAllowed={
            surveyStore.surveySettings?.isProgressPercentageShown ?? false
          }
          progressPercentage={surveyStore.progressPercentage}
          restartSurveyButtonText={surveyStore.restartSurveyTexts?.buttonText}
          saveButtonText={surveyStore.saveDraftTexts?.buttonText}
          previousButtonText={
            surveyStore.isFirstQuestion
              ? undefined
              : surveyStore.surveySettings?.backButtonText
          }
          isInfoButtonAllowed={surveyStore.surveyInfoDialogTitle !== undefined}
          nextButtonText={
            surveyStore.submitButtonText ??
            surveyStore.surveySettings?.nextButtonText
          }
          onLanguageChangeClick={(): void => {
            setIsChangeLanguageDialogOpen(true);
          }}
          onRestartSurveyClick={(): void => {
            currentScreenDetailsStore.resetSubmitConfirmationDialogProperties();
            setIsRestartSurveyDialogOpen(true);
          }}
          onSaveDraftClick={callSaveDraftRPC}
          onInfoClick={(): void => {
            setIsSurveyInfoDialogOpen(true);
          }}
          onPreviousClick={async (): Promise<void> => {
            currentScreenDetailsStore.resetSubmitConfirmationDialogProperties();
            await processSurveyLiveParams(async (surveyLinkId) => {
              surveyStore.currentScreenDetails.backtrackAndGetPreviousQuestion(
                surveyLinkId,
              );
              setDelayedRPCLoading();
            }, navigateTo);
          }}
          onNextClick={callSaveAndGetNextQuestionRPC}
          isFirstQuestion={surveyStore.isFirstQuestion}
          isMessageQuestion={surveyStore.isMessageQuestion}
          startSurveyText={surveyStore.surveySettings?.startButtonText}
          onStartSurveyClick={(): void => {
            if (
              surveyStore.surveySettings?.isRespondentConsentRequired &&
              !surveyStore.hasRespondentConsented
            ) {
              setIsStartSurveyDialogOpen(true);
            } else {
              setIsStartSurveyDialogOpen(false);
              processSurveyLiveParams(async (surveyLinkId) => {
                await surveyStore.currentScreenDetails.startSurvey(
                  surveyLinkId,
                );
              }, navigateTo);
              if (!surveyStore.doesStoreContainErrors) {
                if (
                  surveyStore.surveySettings
                    ?.isLanguageSelectionDisplayedOnStart &&
                  !surveyStore.isSingleLanguageAvailable
                ) {
                  setIsChangeLanguageDialogOpen(true);
                }
              }
            }
          }}
          isWelcomePageVisible={surveyStore.isWelcomePageVisible}
          setIsSnackbarVisible={setIsSnackbarVisible}
          areButtonsDisabled={surveyStore.isNetworkCallMade}
          isSingleLanguageAvailable={surveyStore.isSingleLanguageAvailable}
          surveySectionChildComponentRef={surveySectionChildComponentRef}
          continueButtonText={surveyStore.surveySettings?.continueButtonText}
          networkingStore={networkingStore}
          isLanguageSelectionPersistent={
            surveyStore.surveySettings?.isLanguageSelectionOptionPersistent ??
            false
          }
          isFIThankYouPageShown={
            surveyStore.surveySettings?.isFIThankYouPageLogoShown ?? true
          }
          attachmentConfig={
            surveyStore.rteFileServiceStore.richTextAttachmentProps
          }
        >
          <MandatoryErrorSnackbar
            mandatoryValidationError={
              surveyStore.currentScreenDetails.validationError
            }
            miscellaneousSettingsText={
              surveyStore.surveySettings?.miscellaneousSurveySettingsText
            }
            setMandatoryValidationError={
              surveyStore.currentScreenDetails.setValidationError
            }
          />
          <SurveyInformationPane
            resetAnswerButtonText={
              surveyStore.surveySettings?.resetAnswerButtonText ??
              EMPTY_CHARACTER
            }
            singleSelectStore={
              surveyStore.currentScreenDetails.respondentQuestionDetails
                ? surveyStore.currentScreenDetails.respondentQuestionDetails
                    .singleSelectQuestionV1Model
                : null
            }
            openEndedStore={
              surveyStore.currentScreenDetails.respondentQuestionDetails
                ? surveyStore.currentScreenDetails.respondentQuestionDetails
                    .openEndedQuestionV1Model
                : null
            }
            fontScale={
              surveyStore.surveySettings?.fontSizeScale ??
              FontSizeScale.DEFAULT_SCALE
            }
            questionTitle={
              surveyStore.currentScreenDetails.respondentQuestionDetails
                ?.questionText
            }
            questionDescription={
              surveyStore.currentScreenDetails.respondentQuestionDetails
                ?.questionDescription
            }
            fontFamily={surveyStore.surveySettings?.font ?? PRIMARY_FONT}
            questionType={
              surveyStore.currentScreenDetails.respondentQuestionDetails
                ?.questionType
            }
            onSingleSelectOptionClick={(): void => {
              if (surveyStore.surveySettings?.isAutoProgressEnabled) {
                callSaveAndGetNextQuestionRPC();
              } else {
                currentScreenDetailsStore.clearErrors();
              }
            }}
            multiChoiceStore={
              surveyStore.currentScreenDetails.respondentQuestionDetails
                ? surveyStore.currentScreenDetails.respondentQuestionDetails
                    .multiChoiceQuestionV1Model
                : null
            }
            gridQuestionV1Model={
              surveyStore.currentScreenDetails.respondentQuestionDetails
                ? surveyStore.currentScreenDetails.respondentQuestionDetails
                    .gridQuestionV1Model
                : null
            }
            onAnswerChange={currentScreenDetailsStore.clearErrors}
            welcomePageText={currentScreenDetailsStore.welcomePageText}
            isWelcomePageVisible={surveyStore.isWelcomePageVisible}
            rankingStore={
              surveyStore.currentScreenDetails.respondentQuestionDetails
                ? surveyStore.currentScreenDetails.respondentQuestionDetails
                    .rankingQuestionV1Model
                : null
            }
            isQuestionMandatory={
              currentScreenDetailsStore.respondentQuestionDetails?.isMandatory
            }
            groupQuestionStore={
              surveyStore.currentScreenDetails.respondentQuestionDetails
                ? surveyStore.currentScreenDetails.respondentQuestionDetails
                    .groupQuestionV1Model
                : null
            }
            clearErrors={currentScreenDetailsStore.clearErrors}
            miscellaneousSettingsText={
              surveyStore.surveySettings?.miscellaneousSurveySettingsText
            }
            isRatingScaleOptionVisible={
              surveyStore.surveySettings?.isRatingScaleOptionVisible ?? false
            }
            isFIThankYouPageLogoShown={
              surveyStore.surveySettings?.isFIThankYouPageLogoShown ?? true
            }
            attachmentConfig={
              surveyStore.rteFileServiceStore.richTextAttachmentProps
            }
            isResponseResetAllowed={
              surveyStore.surveySettings?.isResponseResetAllowed ?? false
            }
          />
        </SurveySectionComponent>
      )}
      <LoadingIndicator
        isLoading={surveyStore.isRPCLoading}
        variant="overlay"
        iconColor={"icon"}
      />
      <FeatureInDevelopmentSnackbar
        isSnackbarVisible={isSnackbarVisible}
        setIsSnackbarVisible={setIsSnackbarVisible}
      />
    </RootContainer>
  );
});
